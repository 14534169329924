import styled from 'styled-components';
import { DatasetItemStatus } from '../_types';
import { themeGet } from '@styled-system/theme-get';

const statusColor = (status: DatasetItemStatus): string =>
  ({
    [DatasetItemStatus.UNKNOWN]: '#D8D8D8',
    [DatasetItemStatus.UPLOADING]: '#D8D8D8',
    [DatasetItemStatus.UPLOADED]: '#D8D8D8',
    [DatasetItemStatus.QUEUED_FOR_IMPORT]: '#D8D0BC',
    [DatasetItemStatus.IMPORTING]: '#D2ECFF',
    [DatasetItemStatus.IMPORTED]: '#D2ECFF',
    [DatasetItemStatus.INTEGRATED]: '#00EDA8  ',
    [DatasetItemStatus.ERROR]: '#F7ADAD',
    [DatasetItemStatus.DELETED]: '#F7ADAD',
    [DatasetItemStatus.QUEUED_FOR_LINKING]: '#F9D375',
    [DatasetItemStatus.LINKING]: '#7DCAF9',
    [DatasetItemStatus.LINKED]: '#7DCAF9',
  }[status]);

export const StatusLabel = styled.span<{ status: DatasetItemStatus; reduced: boolean }>`
  white-space: nowrap;
  font-size: ${themeGet('fontSizes.0')};
  font-weight: bold;
  margin: auto;
  padding: ${(props) => (props.reduced ? '3px 4px 1px' : '2px 8px')};
  color: #323232;
  background: ${(props) => statusColor(props.status)};
  border-radius: 100px;
  text-align: center;
  text-transform: capitalize;
`;
