/*
  Used as callback in Array.prototype.map
  assigns `obj` to the value within the array at index `index`

  example:
  [
    {a:0, b:0},
    {a:1, b:1},
    {a:2, b:999},
    {a:3, b:3},
  ].map(mergeForIndex(2, { b: 2 }))
  =>
  [
    {a:0, b:0},
    {a:1, b:1},
    {a:2, b:2},
    {a:3, b:3},
  ]
 */

const assignAtIndex = (index, obj, condition) => (data, currentIndex) =>
  currentIndex === index && (!condition || condition(data)) ? { ...data, ...obj } : data;

export default assignAtIndex;
