import * as React from 'react';

import { useForm } from 'react-hook-form';
import { Headline } from '../../../../text/Headline';
import { AppState, dataSetUdpateGeneralInformation } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonDesign } from '../../../../Button';
import { Modal } from '../../../../Modal/Modal';
import { Dataset } from '../../_types';
import { Input, TextArea } from '../../../../inputs';
import { Box } from '../../../../layout';

interface UpdateCoreParametersProps {
  dataSet: Dataset;
}

type FormValues = {
  name: string;
  description: string;
  metadata_tags: string;
};

export const UpdateCoreParameters: React.FC<UpdateCoreParametersProps> = ({
  dataSet,
}) => {
  const idToken = useSelector((state: AppState) => state.auth.idToken);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  // const [successfullSubmit, setSubmitSuccess] = React.useState(false);

  const { register, handleSubmit } = useForm<FormValues>({});
  const dispatch = useDispatch();

  const onSubmit = handleSubmit((data) => {
    if (!idToken) {
      setModalIsOpen(false);
      return;
    }

    dispatch(
      dataSetUdpateGeneralInformation(
        dataSet.experiment_id,
        data.name,
        data.description,
        data.metadata_tags,
      ),
    );

    dataSet.name = data.name;
    dataSet.description = data.description;
    dataSet.metadata_tags = dataSet.metadata_tags;

    // setSubmitSuccess(true);

    setModalIsOpen(false);
  });

  return (
    <>
      <Button design={ButtonDesign.DEFAULT_SMALL} onClick={() => setModalIsOpen(true)}>
        Update Meta Information
      </Button>
      <Modal
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        title={`Update: ${dataSet.name}`}
      >
        <Headline design={'h2'}>Update general Information</Headline>
        <Headline design={'h3'}>{dataSet.name}</Headline>
        <form onSubmit={onSubmit}>
          <Box marginBottom={40} maxWidth={900}>
            <Input
              id={'name'}
              name={'name'}
              type="text"
              hideInvalid={false}
              maxLength={100}
              ref={register}
              defaultValue={dataSet.name}
              required
            />
            <small>
              Use a crisp and concise name that is easy to understand for others, too
              (max. 100 characters).
            </small>
            <p />

            <TextArea
              name={'description'}
              id={'description'}
              hideInvalid={false}
              ref={register}
              defaultValue={dataSet.description}
              required
            />
            <small>
              Record additional information that can be helpful to interpret results
              later, such as experimental protocols, statistical methods used, number of
              samples etc.
            </small>
            <p />

            <Input
              type="text"
              name={'metadata_tags'}
              id={'metadata_tags'}
              hideInvalid={false}
              ref={register}
              defaultValue={dataSet.metadata_tags ? dataSet.metadata_tags : ''}
              required
            />
            <small>Add some tags to your data, separated by ";".</small>
          </Box>

          <Button type="submit">Udpate Information</Button>
        </form>
      </Modal>
    </>
  );
};

UpdateCoreParameters.displayName = 'UpdateCoreParameters';
