import { call, put, takeEvery } from 'redux-saga/effects';

import { getToken } from '../services/authentication';
import { AppState } from '../store';

const STORAGE_KEY_AUTH_TOKEN = 'STORAGE_KEY_AUTH_TOKEN';

// Actions
export const logIn = (userName: string, password: string) => ({
  type: 'AUTH_LOGIN',
  payload: { userName, password },
});

export const loginError = (errorMessage: string) => ({
  type: 'AUTH_LOGIN_ERROR',
  payload: { errorMessage },
});

export const loginSuccess = (token: string) => ({
  type: 'AUTH_LOGIN_SUCCESS',
  payload: { token },
});

export const logout = () => ({
  type: 'AUTH_LOGOUT',
});

export type AuthAction =
  | ReturnType<typeof logIn>
  | ReturnType<typeof loginError>
  | ReturnType<typeof loginSuccess>
  | ReturnType<typeof logout>;

// State
export interface AuthState {
  completeProfile?: boolean;
  isLoggedIn?: boolean;
  isLoggingIn: boolean;
  errorMessage?: string;
  idToken?: string;
}

const getDefaultState = (): AuthState => {
  const idToken = localStorage.getItem(STORAGE_KEY_AUTH_TOKEN);
  return idToken
    ? {
        isLoggingIn: false,
        isLoggedIn: true,
        idToken,
      }
    : {
        isLoggingIn: false,
        isLoggedIn: false,
      };
};

const defaultState: AuthState = getDefaultState();

// Reducer
const reducer = (state: AuthState = defaultState, action: AuthAction) => {
  switch (action.type) {
    case 'AUTH_LOGIN':
      return {
        ...state,
        isLoggingIn: true,
      };
    case 'AUTH_LOGIN_ERROR': {
      const {
        payload: { errorMessage },
      } = action as ReturnType<typeof loginError>;
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: false,
        errorMessage,
      };
    }
    case 'AUTH_LOGIN_SUCCESS': {
      const {
        payload: { token: idToken },
      } = action as ReturnType<typeof loginSuccess>;
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: true,
        errorMessage: undefined,
        idToken,
      };
    }
    case 'AUTH_LOGOUT': {
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: false,
        idToken: undefined,
        user: undefined,
        errorMessage: undefined,
      };
    }
    default:
      return state;
  }
};

export default reducer;

// Selectors

export const selectToken = (state: AppState) => state.auth.idToken;
export const selectIsLoggedIn = (state: AppState) => state.auth.isLoggedIn;

// Sagas

function* handleLogin(action: ReturnType<typeof logIn>) {
  const { userName, password } = action.payload;

  const { response, error } = yield call(() =>
    getToken(userName, password)
      .then((response) => ({ response }))
      .catch((error) => ({ error })),
  );

  if (response) {
    yield put(loginSuccess(response.accessToken));
    localStorage.setItem(STORAGE_KEY_AUTH_TOKEN, response.accessToken);
  }

  if (error) {
    yield put(loginError(JSON.parse(error.body).detail));
  }
}

function* watchAuth() {
  yield takeEvery('AUTH_LOGIN', handleLogin);
}

function handleLogout() {
  localStorage.removeItem(STORAGE_KEY_AUTH_TOKEN);
}

function* watchLogout() {
  yield takeEvery('AUTH_LOGOUT', handleLogout);
}

export const authSagas = [watchAuth(), watchLogout()];
