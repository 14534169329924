import { call, delay, put, select, take, takeEvery } from 'redux-saga/effects';

import { AnyAction, AppState } from './_types';
import { filePatch, filePost } from '../services/api';
import { dataSetsLoad, dataSetsRemoveSuccess } from './data-sets';
import { UploadFormValues } from '../components/Pages/Datasets/Upload/Upload';

// Actions
export const uploadFile = (
  file: File,
  formValues: UploadFormValues,
  historyPush: (path: string) => void,
) => ({
  type: 'UPLOAD_FILE',
  payload: { file, formValues, historyPush },
});

export const createFileSuccess = (id: string, size: number, bytesReceived: number) => ({
  type: 'CREATE_FILE_SUCCESS',
  payload: { id, size, bytesReceived },
});

export const pauseUpload = (id: string) => ({
  type: 'UPLOAD_PAUSE',
  payload: { id },
});

export const unpauseUpload = (id: string) => ({
  type: 'UPLOAD_UNPAUSE',
  payload: { id },
});

export const uploadChunkSuccess = (id: string, bytesUploaded: number) => ({
  type: 'UPLOAD_CHUNK_SUCCESS',
  payload: { id, bytesUploaded },
});

// Removes an upload completely - usually in case of an error
export const removeUpload = (id: string) => ({
  type: 'UPLOAD_REMOVE',
  payload: { id },
});

export const restartUpload = (id: string) => ({
  type: 'UPLOAD_RESTART',
  payload: { id },
});

export const uploadStagnant = (id: string, stagnant: boolean = true) => ({
  type: 'UPLOAD_STAGNANT',
  payload: { id, stagnant },
});

export const uploadError = (id: string, error: string) => ({
  type: 'UPLOAD_ERROR',
  payload: { id, error },
});

export const uploadComplete = (id: string) => ({
  type: 'UPLOAD_COMPLETE',
  payload: { id },
});

export const resetUploadState = () => ({
  type: 'RESET_UPLOAD_STATE',
});

export type UploadAction =
  | ReturnType<typeof uploadFile>
  | ReturnType<typeof createFileSuccess>
  | ReturnType<typeof pauseUpload>
  | ReturnType<typeof unpauseUpload>
  | ReturnType<typeof uploadComplete>
  | ReturnType<typeof uploadChunkSuccess>
  | ReturnType<typeof restartUpload>
  | ReturnType<typeof uploadStagnant>
  | ReturnType<typeof removeUpload>;

// Reducer

export interface UploadState {
  [id: string]: UploadItem;
}

export interface UploadItem {
  id: string;
  size: number;
  bytesUploaded: number;
  progress: number;
  paused: boolean;
  stagnant: boolean;
  error?: string;
}

const updateUploadItem = (
  state: UploadState,
  id: string,
  itemUpdate: Partial<UploadItem>,
): UploadState => {
  const selectedItem = state?.[id];

  if (!selectedItem) return state;

  return {
    ...state,
    [id]: {
      ...selectedItem,
      ...itemUpdate,
    },
  };
};

const removeUploadItem = (state: UploadState, id: string): UploadState => {
  const stateEntries = Object.entries(state).filter((entry) => entry[0] !== id);

  return Object.fromEntries(stateEntries);
};

const reducer = (state: UploadState = {}, action: AnyAction): UploadState => {
  switch (action.type) {
    case 'CREATE_FILE_SUCCESS': {
      const {
        payload: { id, size, bytesReceived },
      } = action as ReturnType<typeof createFileSuccess>;
      return {
        ...state,
        [id]: {
          id,
          size,
          bytesUploaded: bytesReceived,
          progress: 0,
          paused: false,
          stagnant: false,
        },
      };
    }
    case 'UPLOAD_PAUSE': {
      const {
        payload: { id },
      } = action as ReturnType<typeof pauseUpload>;
      return updateUploadItem(state, id, { paused: true });
    }
    case 'UPLOAD_UNPAUSE': {
      const {
        payload: { id },
      } = action as ReturnType<typeof unpauseUpload>;
      return updateUploadItem(state, id, { paused: false });
    }
    case 'UPLOAD_CHUNK_SUCCESS': {
      const {
        payload: { id, bytesUploaded },
      } = action as ReturnType<typeof uploadChunkSuccess>;
      return updateUploadItem(state, id, {
        progress: bytesUploaded / state[id]?.size,
        bytesUploaded,
        stagnant: false,
      });
    }
    case 'DATA_SETS_REMOVE_SUCCESS': {
      const {
        payload: { id },
      } = action as ReturnType<typeof dataSetsRemoveSuccess>;
      return removeUploadItem(state, id);
    }
    case 'UPLOAD_REMOVE': {
      const {
        payload: { id },
      } = action as ReturnType<typeof removeUpload>;
      return removeUploadItem(state, id);
    }
    case 'UPLOAD_COMPLETE': {
      const {
        payload: { id },
      } = action as ReturnType<typeof uploadComplete>;
      return removeUploadItem(state, id);
    }
    case 'UPLOAD_RESTART': {
      const {
        payload: { id },
      } = action as ReturnType<typeof restartUpload>;
      return updateUploadItem(state, id, {
        paused: false,
        bytesUploaded: 0,
        stagnant: false,
      });
    }
    case 'UPLOAD_STAGNANT': {
      const {
        payload: { id, stagnant },
      } = action as ReturnType<typeof uploadStagnant>;
      return updateUploadItem(state, id, { stagnant });
    }
    case 'UPLOAD_ERROR': {
      const {
        payload: { id, error },
      } = action as ReturnType<typeof uploadError>;
      return updateUploadItem(state, id, { error });
    }
    case 'RESET_UPLOAD_STATE': {
      return {};
    }
    default:
      return state;
  }
};

export default reducer;

// Sagas

function* handleUpload(action: ReturnType<typeof uploadFile>) {
  const idToken = yield select((state: AppState) => state.auth.idToken);
  const { file, formValues } = action.payload;
  let maxChunkSize = 262144 * 2; // must be multiple of 262144

  const { status: postStatus, dataSetFile: postData } = yield call(filePost, {
    idToken,
    fileName: file.name,
    fileSize: file.size,
    formValues,
  });

  const id = postData.id || file.name;
  yield put(createFileSuccess(id, file.size, postData.bytes_received));
  yield put(dataSetsLoad());

  if ([200, 201].indexOf(postStatus) === -1) {
    yield put(
      uploadError(
        id,
        typeof postData.detail !== 'string'
          ? JSON.stringify(postData.detail)
          : postData.detail,
      ),
    );

    return;
  }

  yield take('DATA_SETS_LOAD_SUCCESS');
  const dataSet = yield select((state: AppState) =>
    state.dataSets.dataSets.find((dataSet) => dataSet.id === id),
  );

  if (dataSet?.id) {
    action.payload.historyPush(`/datasets/${dataSet?.id}`);
  }

  let uploading: boolean = true;

  while (uploading) {
    const { paused = false, bytesUploaded } = yield select(
      (state: AppState) => state.upload[id],
    );

    if (paused) {
      // wait until the upload is not paused any longer
      yield delay(200);
    } else {
      const bytesLeft = file.size - bytesUploaded;
      const chunkSize = Math.min(bytesLeft, maxChunkSize);

      try {
        // Measure how long a request takes. If it does not take too long, increase the chunk size
        const start = new Date().getTime();

        const chunkStart: number = bytesUploaded;
        const chunkEnd: number = Math.min(chunkStart + chunkSize, file.size);
        const blob: Blob = file.slice(chunkStart, chunkEnd);

        // console.log(`Bytes uploaded: ${bytesUploaded}`);
        // console.log(`Chunk size: ${chunkSize}`);
        // console.log(`File size: ${action.payload.file.size}`);
        // console.log(`Chunk start: ${chunkStart}`);
        // console.log(`Chunk end: ${chunkEnd}`);
        // console.log(`Blob size: ${blob.size}`);

        const { status: patchStatus, dataSetFile: patchData } = yield call(filePatch, {
          idToken,
          fileName: file.name,
          fileSize: file.size,
          chunkStart,
          chunkEnd,
          blob,
        });

        // increase max chunk size if necessary
        const responseTime = new Date().getTime() - start;
        if (responseTime < 1000) {
          maxChunkSize *= 2;
        }

        switch (patchStatus) {
          case 400: // Bad Request - Assume session to be expired. Fail hard.
          case 404: // Not Found
          case 409: // Conflict
          case 422: // Unprocessable entity
            uploading = false;

            yield put(uploadError(id, patchData.detail));
            yield put(removeUpload(id));
            break;
          case 408: // Request Timeout
          case 429: // Too many requests!
          case 500: // Internal Server Error
          case 502: // Bad Gateway
          case 503: // Service Unavailable
          case 504: // Gateway Timeout
            // Wait a bit. Without an updated state the same upload procedure will be called next time.
            yield delay(7000);
            break;
          case 200:
            if (patchData.bytes_received === file.size) {
              // All is uploaded successfully!
              uploading = false;
            }
            break;
          case 206: // Chunk successfully uploaded
            yield put(uploadChunkSuccess(id, patchData.bytes_received));
          // Intentional fallthrough - Keep uploading chunks
        }
      } catch (e) {
        // A network error occurred during upload. Mark this upload as "stagnant". Appropriate warnings are be shown in the view.
        yield put(uploadStagnant(id, true));
        yield delay(1000);
      }
    }

    const stillExists = yield select((state: AppState) => !!state.upload[id]);
    if (!stillExists) return;
  }

  yield delay(3500);
  yield put(uploadComplete(id));
  yield put(dataSetsLoad());
}

function* watchUpload() {
  yield takeEvery('UPLOAD_FILE', handleUpload);
}

export const uploadSagas = [watchUpload()];
