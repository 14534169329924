export * from './style/index';
export * from './uid';
export * from './routing';
export { default as assignAtIndex } from './assign-at-index';
export { default as selectionStateToQuery } from './selection-state-to-query';

export const getColorByIndex = (index) => {
  const colors = ['#91D0FF', '#d138ff', '#ffcb52', '#6bffc5', '#ffcb52', '#323232'];
  return colors[index % colors.length];
};

export const getChannelFromHex = (hex, channel) => {
  const { shift, mask } = {
    red: {
      shift: 16,
      mask: 0xff0000,
    },
    green: {
      shift: 8,
      mask: 0xff00,
    },
    blue: {
      shift: 0,
      mask: 0xff,
    },
  }[channel];
  return (parseInt(hex.trim().replace(/#/, ''), 16) & mask) >> shift;
};

export const clamp = (value, min, max) =>
  Math.min(Math.max((parseFloat(value) - min) / (max - min), 0), 1);

export const formatInteger = (integer) =>
  parseInt(integer, 10)
    .toString()
    .split('')
    .map((digit, index, { length }) => {
      if (index === 0 || (length - index) % 3 !== 0) return digit;
      return `.${digit}`;
    })
    .join('');

export function isFloat(inputString) {
  const parsed = parseFloat(inputString);

  return !isNaN(parsed) && parsed.toString() === inputString;
}

export function toFloat(input: string) {
  // Determine if the input uses German formatting (comma as decimal separator)
  let isGermanFormat = input.includes(',') && (input.match(/\./g) || []).length <= 1;

  if (isGermanFormat) {
    // Replace comma with a dot for German format
    return parseFloat(input.replace(',', '.'));
  } else {
    // Remove commas for English format and convert to float
    return parseFloat(input.replace(/,/g, ''));
  }
}

export function toFormattedFloat(input: string) {
  let value = toFloat(input); // Using the previous toFloat function

  // Check if the value is below the threshold for scientific notation
  if (Math.abs(value) < 1e-4 && value !== 0) {
    return value.toExponential(4); // Format in scientific notation with 4 digits
  } else {
    return value.toFixed(4); // Round to 4 digits and keep in normal notation
  }
}
