import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { ThemeProvider } from 'styled-components';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { GlobalStyle } from './components/layout';
import App from './components/App';
import { standard } from './themes';
import { rootSaga, rootReducer } from './store';
import { ApiProvider } from './components/ApiProvider';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

const persistState = process.env.REACT_APP_SAVE_APP_STATE_PERSISTENTLY === 'true';

const readPersistentState = () => {
  if (!persistState) return null;
  try {
    return JSON.parse(localStorage.persistentState);
  } catch (e) {
    return null;
  }
};

const preloadedState = readPersistentState();

const store = createStore(
  rootReducer,
  preloadedState || {},
  composeEnhancers(applyMiddleware(thunk), applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(rootSaga);

if (persistState) {
  setInterval(() => {
    localStorage.persistentState = JSON.stringify(store.getState());
  }, 500);
} else {
  localStorage.removeItem('persistentState');
}

const history = createBrowserHistory();
ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={standard}>
      <Router history={history}>
        <ApiProvider>
          <GlobalStyle noPadding />
          <App />
        </ApiProvider>
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);
