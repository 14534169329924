import { combineReducers } from 'redux';

import selection from './selection';
import experiments from './experiments';
import auth from './auth';
import help from './help';
import dataSets from './data-sets';
import requests from './userRequests';
import upload from './upload';
import user from './user';
import search from './search';
import annotate from './annotate';

export * from './_types';

export const rootReducer = combineReducers({
  experiments,
  selection,
  auth,
  help,
  dataSets,
  requests,
  search,
  annotate,
  upload,
  user,
});
