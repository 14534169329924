import { Biotype } from '../_types';

export enum ColumnTag {
  'Unknown' = 0,
  'Link identifier' = 1,
  'Locus: chromosome' = 2,
  'Locus: start position' = 3,
  'Locus: stop position' = 4,
  'Custom tag 1' = 5,
  'Effect size measure' = 6,
  'Significance' = 7,
  'Adjusted significance' = 8,
  'Locus: allele 1' = 9,
  'Locus: allele 2' = 10,
  'Custom tag 2' = 11,
  'Custom tag 3' = 12,
  'Custom tag 4' = 13,
  'Custom tag 5' = 14,
  'Score' = 15,
  'Data Slice' = 500,
  '%cells in cell type (PC.1)' = 501,
  '%cells outside (PC.2)' = 502,
}

export interface ColumnOption {
  id: ColumnTag;
  description: string;
  mandatory?: boolean;
  biotype?: Biotype;
}

export const columnOptions: ColumnOption[] = [
  {
    id: ColumnTag['Link identifier'],
    description: 'Link identifier',
    mandatory: true,
    biotype: Biotype.Gene,
  },
  {
    id: ColumnTag['Link identifier'],
    description: 'Link identifier',
    mandatory: true,
    biotype: Biotype['Gene Symbol'],
  },
  {
    id: ColumnTag['Link identifier'],
    description: 'Link identifier',
    mandatory: true,
    biotype: Biotype.Transcript,
  },
  {
    id: ColumnTag['Link identifier'],
    description: 'Link identifier',
    mandatory: true,
    biotype: Biotype.Protein,
  },
  {
    id: ColumnTag['Link identifier'],
    description: 'Link identifier',
    biotype: Biotype.Variant,
  },
  {
    id: ColumnTag['Link identifier'],
    description: 'Link identifier',
    biotype: Biotype['Genomic Range'],
  },
  {
    id: ColumnTag['Custom tag 1'],
    description: 'Custom tag 1',
  },
  {
    id: ColumnTag['Effect size measure'],
    description: 'Effect size measure',
  },
  {
    id: ColumnTag.Significance,
    description: 'Significance',
  },
  {
    id: ColumnTag['Adjusted significance'],
    description: 'Adjusted significance',
  },
  {
    id: ColumnTag['Locus: chromosome'],
    description: 'Locus: chromosome',
    mandatory: true,
    biotype: Biotype.Variant,
  },
  {
    id: ColumnTag['Locus: start position'],
    description: 'Locus: start position',
    mandatory: true,
    biotype: Biotype.Variant,
  },
  {
    id: ColumnTag['Locus: chromosome'],
    description: 'Locus: chromosome',
    mandatory: true,
    biotype: Biotype['Genomic Range'],
  },
  {
    id: ColumnTag['Locus: start position'],
    description: 'Locus: start position',
    mandatory: true,
    biotype: Biotype['Genomic Range'],
  },
  {
    id: ColumnTag['Locus: stop position'],
    description: 'Locus: stop position',
    mandatory: true,
    biotype: Biotype['Genomic Range'],
  },
  {
    id: ColumnTag['Locus: allele 1'],
    description: 'Locus: allele 1',
    biotype: Biotype.Variant,
  },
  {
    id: ColumnTag['Locus: allele 2'],
    description: 'Locus: allele 2',
    biotype: Biotype.Variant,
  },
  {
    id: ColumnTag['Custom tag 2'],
    description: 'Custom tag 2',
  },
  {
    id: ColumnTag['Custom tag 3'],
    description: 'Custom tag 3',
  },
  {
    id: ColumnTag['Custom tag 4'],
    description: 'Custom tag 4',
  },
  {
    id: ColumnTag['Custom tag 5'],
    description: 'Custom tag 5',
  },
  {
    id: ColumnTag['Score'],
    description: 'Score',
  },
  {
    id: ColumnTag['Data Slice'],
    description: 'Data Slice',
  },
  {
    id: ColumnTag['%cells in cell type (PC.1)'],
    description: '%cells in cell type (PC.1)',
  },
  {
    id: ColumnTag['%cells outside (PC.2)'],
    description: '%cells outside (PC.2)',
  },
];

export const errorMessages = {
  duplicate: 'Duplicate entry',
  missingColumn: 'Please assign this column:',
};
