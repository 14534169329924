import styled, { css } from 'styled-components';
import { compose, gridArea, layout, space } from 'styled-system';
import { FontAwesomeIcon as UnstyledFontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as T from './_types';
import { mapPropToCss } from '../utils';
import { linkCSS } from '../Link';
import { themeGet } from '@styled-system/theme-get';

export const Loading = styled(UnstyledFontAwesomeIcon)`
  --loading-icon-size: 20px;
  width: var(--loading-icon-size) !important;
  height: var(--loading-icon-size) !important;
  position: absolute;
  left: calc(50% - var(--loading-icon-size) / 2);
  top: calc(50% - var(--loading-icon-size) / 2);
`;

export const FontAwesomeIcon = styled(UnstyledFontAwesomeIcon)`
  margin-right: 0.25rem;
`;

const highlightHoverCSS = css<T.ButtonContainerProps>`
  box-shadow: inset 2px -2px 0 0 ${themeGet('colors.gold-dark')};
  background: ${themeGet('colors.gold')};
  color: inherit;
`;

const highlightCSS = css<T.ButtonContainerProps>`
  border: 0;

  padding: calc(${themeGet('space.1')} + 2px) calc(${themeGet('space.4')} + 2px);

  box-shadow: inset 20px -20px 0 10px ${themeGet('colors.gold')};
  background: ${themeGet('colors.gold')};
  color: inherit;

  &:not([disabled]) {
    &:focus,
    &:hover {
      ${highlightHoverCSS};
    }
    ${(props) => props.hovered && highlightHoverCSS};
    ${(props) => props.focused && highlightHoverCSS};
  }
`;

const dangerHoverCSS = css<T.ButtonContainerProps>`
  background: ${themeGet('colors.tomato')};
`;

const dangerCSS = css<T.ButtonContainerProps>`
  border-color: ${themeGet('colors.tomato')};
  color: ${themeGet('colors.tomato')};

  &:not([disabled]) {
    &:focus,
    &:hover {
      ${dangerHoverCSS};
    }
    ${(props) => props.hovered && dangerHoverCSS};
    ${(props) => props.focused && dangerHoverCSS};
  }
`;

const miniatureCSS = css<T.ButtonContainerProps>`
  display: inline;
  padding: 0 ${themeGet('space.2')};
  border: 0;
  border-radius: ${themeGet('radii.3')};
  font-weight: inherit;
  font-size: inherit;

  transition: none;
  background: transparent;
  color: ${themeGet('colors.text')};
`;

const linkButtonHoverCSS = css<T.ButtonContainerProps>`
  background-color: inherit;
  color: inherit;
`;

const linkButtonCSS = css<T.ButtonContainerProps>`
  display: inline;
  padding: 0;
  border: 0;
  border-radius: 0;
  font-weight: 500;

  transition: none;
  background: transparent;
  color: ${themeGet('colors.text')};

  &:not([disabled]) {
    &:focus,
    &:hover {
      ${linkButtonHoverCSS};
    }
    ${(props) => props.hovered && linkButtonHoverCSS};
    ${(props) => props.focused && linkButtonHoverCSS};
  }

  ${linkCSS};
`;

const lightButtonHoverCSS = css<T.ButtonContainerProps>`
  background-color: inherit;
  color: inherit;
`;

const lightButtonCSS = css<T.ButtonContainerProps>`
  display: inline;
  padding: 0;
  border: 0;
  border-radius: 0;
  font-weight: 500;

  transition: none;
  background: transparent;
  color: ${themeGet('colors.text')};

  &:not([disabled]) {
    &:focus,
    &:hover {
      ${lightButtonHoverCSS};
    }
    ${(props) => props.hovered && lightButtonHoverCSS};
    ${(props) => props.focused && lightButtonHoverCSS};
  }
`;

const buttonFocusHoverCSS = css<T.ButtonContainerProps>`
  cursor: pointer;
  background-color: ${themeGet('colors.text')};
  color: white;
`;

const defaultSmallCSS = css<T.ButtonContainerProps>`
  font-size: ${themeGet('fontSizes.0')};
  border-width: 0.5px;
  min-width: ${themeGet('space.3')};
  padding: ${themeGet('space.0')} ${themeGet('space.2')};
`;

export const buttonCSS = css<T.ButtonContainerProps>`
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  margin-right: auto;
  padding: ${themeGet('space.1')} ${themeGet('space.4')};
  border: 2px solid #323232;
  border-radius: ${themeGet('radii.2')};
  transition: all 0.25s ease;
  background-color: transparent;
  color: ${themeGet('colors.text')};
  font-family: ${themeGet('fonts.default')};
  font-size: ${themeGet('fontSizes.2')};
  font-weight: 600;
  line-height: 1.5em;
  text-wrap: nowrap;

  &:not([disabled]) {
    &:focus,
    &:hover {
      ${buttonFocusHoverCSS};
    }
  }

  &:focus {
    outline: 0;
  }

  ${(props) => props.hovered && buttonFocusHoverCSS};
  ${(props) => props.focused && buttonFocusHoverCSS};

  &[disabled] {
    opacity: 0.4;
  }

  ${mapPropToCss('design', {
    highlight: highlightCSS,
    danger: dangerCSS,
    link: linkButtonCSS,
    light: lightButtonCSS,
    mini: miniatureCSS,
    defaultSmall: defaultSmallCSS,
  })};
`;

export const ButtonContainer = styled.button<T.ButtonContainerProps>`
  ${buttonCSS};
  ${compose(space, gridArea, layout)};

  ::-moz-focus-inner {
    border: 0;
  }
`;

export const Label = styled.span<{ fade: boolean }>`
  transition: opacity 250ms ease-out;
  opacity: ${(props) => (props.fade ? '.5' : '1')};
`;
