import styled from 'styled-components';

export const Button = styled.button`
  --background-opacity: 0;
  --size: 28px;
  width: var(--size);
  height: var(--size);
  box-sizing: border-box;
  border: 0;
  padding: 0;
  margin: 0;
  position: relative;
  background: transparent;
  cursor: pointer;

  :disabled {
    cursor: not-allowed;
  }

  ::before {
    content: ' ';
    position: absolute;
    background: hsla(180, 10%, 95%, var(--background-opacity));
    border-radius: 100vmax;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transition: transform 250ms ease-out, opacity 250ms ease-out;
  }

  > * {
    position: relative;
    color: #666;
    transition: color 250ms ease-out;
  }
  
  ::-moz-focus-inner {
    border: 0
  }
  
  :hover > * {
    color: #000;
  }

  :focus,
  :hover {
    --background-opacity: .5;
    &::before {
      transform: scale(1);
    }
  }

  :active::before {
    transform: scale(.9);
  }
`;
