import React from 'react';
import { OpenAPIProvider } from 'react-openapi-client';
import { useSelector } from 'react-redux';
import { OpenAPIV3 } from 'openapi-types';

import { selectIsLoggedIn, selectToken } from '../../store';

const api_server: OpenAPIV3.ServerObject = {
  url: String(window.env.API_URL),
  description: 'API Server',
};

const definition = window.env.API_URL + 'openapi.json';

export const ApiProvider: React.FC = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const token = useSelector(selectToken);
  const axiosConfigDefaults = React.useMemo(
    () => ({
      withCredentials: true,
      headers: isLoggedIn ? { Authorization: 'Bearer ' + token } : undefined,
    }),
    [isLoggedIn, token],
  );
  return (
    <OpenAPIProvider
      definition={definition}
      withServer={api_server}
      axiosConfigDefaults={axiosConfigDefaults}
    >
      {children}
    </OpenAPIProvider>
  );
};
