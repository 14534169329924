import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../../../store';
import { pauseUpload, unpauseUpload } from '../../../../../store/upload';
import { DatasetItemStatus } from '../../_types';

export const useDetailsUpload = (id: string) => {
  const dispatch = useDispatch();
  let { stagnant = false, progress, paused = false, error } = useSelector(
    (state: AppState) => state.upload[id] || {},
  );

  const { status, uploadProgress, importProgress, linkProgress } = useSelector(
    (state: AppState) =>
      state.dataSets.dataSets.find((dataSet) => dataSet.id === id) || {
        status: DatasetItemStatus.UNKNOWN,
        uploadProgress: 0,
        importProgress: 0,
        linkProgress: 0,
      },
  );

  switch (status) {
    case DatasetItemStatus.UPLOADING || DatasetItemStatus.UPLOADED:
      progress = progress || uploadProgress;
      break;
    case DatasetItemStatus.QUEUED_FOR_IMPORT:
    case DatasetItemStatus.IMPORTING || DatasetItemStatus.IMPORTED:
      progress = importProgress;
      break;
    case DatasetItemStatus.QUEUED_FOR_LINKING:
    case DatasetItemStatus.LINKING || DatasetItemStatus.LINKED:
      progress = linkProgress;
      break;
  }

  const pause = React.useCallback(() => dispatch(pauseUpload(id)), [dispatch, id]);
  const unpause = React.useCallback(() => dispatch(unpauseUpload(id)), [dispatch, id]);
  return { progress, paused, pause, unpause, stagnant, error };
};
