import styled from 'styled-components';

export const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  line-height: 1.6;
`;

export const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1.5rem;
  padding-left: 0;
  text-align: left;
`;

export const Section = styled.div`
  margin-bottom: 1.5rem;
  padding-left: 0;
`;

export const Address = styled.address`
  font-style: normal;
  margin-bottom: 1.5rem;
  padding-left: 0;
  white-space: pre-line;
`;

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
`;
