import styled from 'styled-components';
import { compose, gridArea, layout, space } from 'styled-system';

import * as T from './_types';

export const Container = styled.div<T.StyleProps>`
  ${compose(gridArea, space, layout)};
  max-width: 100%;
  overflow: auto;
  scrollbar-width: thin;
`;
