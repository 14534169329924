import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';

import * as S from './_style';
import * as T from './_types';
import * as E from './_effects';

import { SingleFile } from './SingleFile';

export const FileSelect = React.forwardRef<HTMLInputElement, T.FileSelectProps>(
  ({ style, className, onChange, name, required }, ref) => {
    const { file, handleFileChange, setFile } = E.useFileSelect(
      onChange as React.ChangeEventHandler<HTMLInputElement>,
    );

    // Reset files on ref change (e.g. reset)
    React.useEffect(() => setFile(undefined), [ref, setFile]);

    return (
      <S.Container style={style} className={className} empty={!file}>
        <S.Input
          type="file"
          ref={ref}
          onChange={handleFileChange}
          name={name}
          required={required}
        />

        {!!file && <SingleFile key={file.name} fileName={file.name} flexGrow={1} />}

        {!file && (
          <S.UploadLabel>
            <small>Drag and drop your file here or</small>
            <br />
            <FontAwesomeIcon icon={faCloudUploadAlt} style={{ marginRight: '5px' }} />
            <span>{`Browse & select`}</span>
          </S.UploadLabel>
        )}
      </S.Container>
    );
  },
);

FileSelect.displayName = 'FileSelect';
