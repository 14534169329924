import React from 'react';
import { useSelector } from 'react-redux';

import * as T from './_types';
import { DatasetStatusVisual } from './DatasetStatusVisual';
import { AppState } from '../../../../store';
import { useDetailsUpload } from '../DatasetDetails/DetailsUpload/_effects';
import { DatasetItemStatus } from '../_types';

export const DatasetStatus: React.FC<T.DatasetStatusProps> = ({
  style,
  className,
  id,
  reduced,
}) => {
  const dataSet = useSelector((state: AppState) =>
    state.dataSets.dataSets.find((dataSet) => dataSet.id === id),
  );
  const { progress } = useDetailsUpload(id);
  reduced !== true ? (reduced = false) : (reduced = true);

  if (!dataSet) return null;

  // artificially recode State UPLOADED into QUEUED FOR IMPORT
  if (dataSet.status === DatasetItemStatus.UPLOADED) {
    dataSet.status = DatasetItemStatus.QUEUED_FOR_IMPORT;
  }

  return (
    <DatasetStatusVisual
      style={style}
      className={className}
      status={dataSet.status}
      progress={progress}
      reduced={reduced}
    />
  );
};

DatasetStatus.displayName = 'DatasetStatus';
