import React from 'react';

import * as T from './_types';

export const useInput: T.InputHook = (defaultValue = '') => {
  const [value, setValue] = React.useState<string>(defaultValue);
  const onChange = (event: React.SyntheticEvent<HTMLInputElement>) =>
    void setValue(event.currentTarget.value);
  return [value, onChange, setValue];
};

export const useCheckbox: T.CheckboxHook = (defaultValue = false) => {
  const [value, setValue] = React.useState<boolean>(defaultValue);
  const onChange = (event: React.SyntheticEvent<HTMLInputElement>) =>
    void setValue(event.currentTarget.checked);
  return [value, onChange];
};
