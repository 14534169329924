import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';

import * as S from './_style';

export interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: IconProp;
  size?: SizeProp;
}
export const IconButton: React.FC<IconButtonProps> = ({
  style,
  className,
  icon,
  size = '1x',
  ...props
}) => (
  <S.Button style={style} className={className} {...props}>
    <FontAwesomeIcon icon={icon} size={size} />
  </S.Button>
);

IconButton.displayName = 'IconButton';
