import * as React from 'react';

export const useSelect = (
  ref: ((instance: HTMLSelectElement | null) => void) | React.MutableRefObject<HTMLSelectElement | null> | null,
  value: string | string[] | number | undefined
) => {
  const selectRef = React.useRef<HTMLSelectElement>(null);
  React.useImperativeHandle(
    ref,
    // @ts-ignore
    () => selectRef.current,
  );
  const [label, setLabel] = React.useState<string | undefined>();
  React.useEffect(
    () => {
      const select = selectRef?.current;
      if (!select) return;
      const updateLabel = () => {
        const selectedOption = Array
          .from(select.options)
          .find(option => option.value === (value || select.value));
        if (!selectedOption) return;
        setLabel(selectedOption.label);
      };
      updateLabel();
      select.addEventListener('change', updateLabel);
      return () => select.removeEventListener('change', updateLabel);
    },
    [ref, value]
  );
  return { label, selectRef };
};
