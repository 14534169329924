import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { compose, gridArea, layout, space } from 'styled-system';

const disabledContainerCSS = css`
  ::before {
    border-color: #bbb;
  }
  ::after {
    border-color: #bbb;
  }
`;

const enabledContainerCSS = css`
  :hover::before {
    border-color: #666;
    transition: 0s;
  }
`;

export const Container = styled.div`
  --container-size: 20px;
  --checkbox-size: 20px;

  display: inline-block;
  width: var(--container-size);
  height: var(--container-size);
  position: relative;

  ${compose(layout, gridArea, space)};
`;

export const Checkbox = styled.input.attrs(() => ({ type: 'checkbox' }))`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  z-index: 2;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  opacity: 0;

  :disabled {
    cursor: not-allowed;
  }
`;

export const Checkmark = styled.div<{ disabled: boolean }>`
  position: relative;

  ::before,
  ::after {
    content: ' ';
    display: block;
    position: absolute;
    box-sizing: border-box;
    z-index: 0;
  }

  ::before {
    border: 1px solid #bbb;
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    left: calc((var(--container-size) - var(--checkbox-size)) / 2);
    top: calc((var(--container-size) - var(--checkbox-size)) / 2);
    border-radius: 5px;
    transition: border-color 150ms ease-out;
    box-sizing: border-box;
  }

  ::after {
    border: 2px solid ${themeGet('colors.action')};
    width: 7px;
    height: 10px;
    border-top: 0;
    border-left: 0;
    transform: rotate(40deg);
    left: calc(var(--container-size) * 0.32);
    top: calc(var(--container-size) * 0.22);
    border-radius: 0 0 1px 0;
    display: none;
  }
  
  ${Checkbox}:checked + &::after {
    display: block;
  }

  ${(props) => (props.disabled ? disabledContainerCSS : enabledContainerCSS)};
`;
