import ReactModal from 'react-modal';
import React from 'react';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import styled from 'styled-components';

import { IconButton } from '../IconButton';

const Container = styled.div`
  display: flex;
`;

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: '20px 20px 25px 0',
    border: '0',
    borderRadius: '10px',
    transform: 'translate(-50%, -50%)',
  },

  overlay: {
    backgroundColor: 'rgba(0, 0 ,0 , 0.1)',
  },
};

type ModalProps = {
  children: React.ReactNode;
  onClose: () => void;
  open: boolean;
  title?: string;
};

ReactModal.setAppElement('#root');

export const Modal = ({ children, onClose, open, title }: ModalProps) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const closeModal = () => {
    setModalIsOpen(false);
    onClose();
  };
  const afterOpenModal = () => {};

  React.useEffect(() => {
    setModalIsOpen(open);
  }, [open]);

  return (
    <ReactModal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={modalStyle}
      contentLabel={title}
    >
      <Container>
        <div>{children}</div>

        <div>
          <IconButton onClick={closeModal} icon={faTimesCircle} size={'lg'} />
        </div>
      </Container>
    </ReactModal>
  );
};
