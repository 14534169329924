import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Faded = styled.span`
  opacity: .5;
`;

const Normal = styled.span``;

const getIndexes = (str, search) => {
  if (search === '') return [];
  const indexes = [];
  str = str.toLowerCase();
  search = search.toLowerCase();
  let index = -1;
  while ((index = str.indexOf(search, index + 1)) !== -1) {
    indexes.push(index);
  }
  return indexes;
};

const fillIndexes = (highlightIndexes, textLength, highlightLength) => {
  const isHighlight = (_, index) => highlightIndexes.map(highlightIndex =>
      index >= highlightIndex && index < highlightIndex + highlightLength
    )
    .includes(true);
  const pattern = Array(textLength)
    .fill(false)
    .map(isHighlight);
  return pattern
    .reduce((acc, isHighlight, index) => {
      if (index === 0) return [ {
        index,
        isHighlight,
        length: 1,
      } ]; // if it's the first, just return it
      const firstValues = acc.slice(0, acc.length - 1);
      const previousValue = acc[acc.length - 1];

      // if the current object is also (not) a highlight like its predecessor, just increase the length counter
      if (previousValue.isHighlight === isHighlight) {
        return [
          ...firstValues,
          {
            ...previousValue,
            length: previousValue.length + 1,
          }
        ];
      }

      // ... if it differs from its predecessor, append it
      return [
        ...acc,
        {
          index,
          isHighlight,
          length: 1,
        }
      ];
    }, []);
};

export const HighlightText = ({ text, highlight }) => {
  if (!highlight) return <Normal>{text}</Normal>;

  const spanIndexes = fillIndexes(
    getIndexes(text, highlight),
    text.length, highlight.length
  );

  return (
    <Fragment>
      {spanIndexes.map(({ isHighlight, index, length }) => {
        const Component = isHighlight ? Normal : Faded;
        const textFragment = text.substr(index, length);
        const key = textFragment + isHighlight + index;
        return (
          <Component key={key}>
            {textFragment}
          </Component>
        );
      })}
    </Fragment>
  );
};

HighlightText.propTypes = {
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
};
