import { AppState } from './_types';
import { call, delay, put, select, takeEvery } from 'redux-saga/effects';
import {
  SearchQueryParameter,
  SearchResult,
  SearchResultRequest,
} from '../components/CellMap';
import { fetchSearchResults } from '../services/api';
import { dataSetsLoad } from './data-sets';
import { logout } from './auth';

export const loadSearch = (query: string) => ({
  type: 'GET_SEARCH_RESULTS',
  payload: { query },
});

export const searchLoadSuccess = (searchResult: SearchResult) => ({
  type: 'SEARCH_LOAD_SUCCESS',
  payload: { searchResult },
});

export const searchLoadError = (errorMessage: string) => ({
  type: 'SEARCH_LOAD_ERROR',
  payload: { errorMessage },
});

export const resetSearchResult = () => ({
  type: 'RESET_SEARCH_RESULT',
});

export type SearchAction =
  | ReturnType<typeof loadSearch>
  | ReturnType<typeof searchLoadSuccess>
  | ReturnType<typeof searchLoadError>;

export interface SearchResultState {
  loading: boolean;
  errorMessage?: string;
  searchResult: SearchResult;
}

export const defaultState: SearchResultState = {
  loading: false,
  searchResult: {
    search_string: '',
    type: '',
    dataset_id: '',
    cutoff_type: '',
    result: {},
    status: 0,
    dataset_meta_tags: {},
  },
};

// Reducer
const reducer = (
  state: SearchResultState = defaultState,
  action: SearchAction,
): SearchResultState => {
  switch (action.type) {
    case 'GET_SEARCH_RESULTS':
      const {
        payload: { query },
      } = action as ReturnType<typeof loadSearch>;
      return {
        ...state,
        loading: true,
      };
    case 'SEARCH_LOAD_SUCCESS': {
      const {
        payload: { searchResult },
      } = action as ReturnType<typeof searchLoadSuccess>;
      return {
        ...state,
        errorMessage: undefined,
        loading: false,
        searchResult,
      };
    }
    case 'SEARCH_LOAD_ERROR': {
      const {
        payload: { errorMessage },
      } = action as ReturnType<typeof searchLoadError>;
      return {
        ...state,
        loading: false,
        errorMessage,
      };
    }
    case 'RESET_SEARCH_RESULT':
      return defaultState;
    default:
      return state;
  }
};
export default reducer;

function* handleSearchLoad(action: ReturnType<typeof loadSearch>) {
  let searchResult = yield select((state: AppState) => state.search);
  searchResult.loading = true;
  searchResult.searchResult = {
    request: {} as SearchResultRequest,
    result: {},
    status: 0,
  };
  searchResult.errorMessage = '';
  const idToken = yield select((state: AppState) => state.auth.idToken);
  const postData: SearchQueryParameter = {
    search_string: action.payload.query,
  };
  try {
    const searchResult: SearchResult = yield call(fetchSearchResults, idToken, postData);

    // enforce to bind datasets
    let availableDatasets = yield select((state: AppState) => state.dataSets);

    // ENFORCE to wait until datasets are loaded if loads of data for slow API. But dont max this out.
    if (availableDatasets.dataSets.length === 0) {
      yield put(dataSetsLoad());
      let i: number = 0;
      while (i < 20 && availableDatasets.dataSets.length === 0) {
        i += 1;
        yield delay(500); // 500ms delay to avoid busy waiting
        availableDatasets = yield select((state: AppState) => state.dataSets);
      }
    }
    searchResult.dataset_meta_tags = {};

    yield put(searchLoadSuccess(searchResult));
  } catch (e) {
    yield put(searchLoadError(e.message || e.errorMessage || 'Unknown error'));
  }
}

function* watchSearchLoad() {
  yield takeEvery('GET_SEARCH_RESULTS', handleSearchLoad);
}

export const searchSagas = [watchSearchLoad()];
