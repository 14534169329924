import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { load } from './_load';
import LoadPdf from '../../Pages/LoadPdf/LoadPdf';
import LegalDisclosure from '../../Pages/LegalDisclosure/LegalDisclosure';

export const RouteSwitch: React.FC = () => (
  <Switch>
    <Route exact path="/" component={load.Home} />
    <Route exact path="/explore" component={load.Explore} />
    <Route exact path="/components" component={load.Components} />
    <Route exact path="/datasets/:fileId?" component={load.Datasets} />
    <Route exact path="/account" component={load.Account} />
    <Route exact path="/register" component={load.Register} />
    <Route exact path="/login" component={load.Login} />
    <Route exact path="/logout" component={load.Logout} />
    <Route exact path="/restore-password" component={load.RestorePassword} />
    <Route exact path="/set-password/:token?" component={load.SetPassword} />
    <Route exact path="/privacy-policy" component={load.PrivacyPolicy} />
    <Route exact path="/method-description" component={load.MethodDescription} />
    <Route exact path="/downloads" component={load.DownloadCenter} />
    <Route exact path="/search" component={load.SearchCenter} />
    <Route exact path="/SaaS-TandC" component={LoadPdf} />
    <Route exact path="/legal" component={LegalDisclosure} />
    <Route path="*" component={load.Error404} />
  </Switch>
);
