import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AppState } from '../../store';

export const useLoginRequirement = () => {
  const { isLoggedIn, isLoggingIn } = useSelector((state: AppState) => state.auth);
  const { push } = useHistory();
  React.useEffect(() => {
    if (!isLoggingIn && isLoggedIn === false) {
      push('/login');
    }
  }, [isLoggedIn, isLoggingIn, push]);
};
