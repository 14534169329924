import React from 'react';
import { useSelector } from 'react-redux';

import * as S from './_style';

import { MenuBar } from './MenuBar';
import { Footer } from './Footer';
import { AppState } from '../../../store';

export interface FrameProps {
  style?: React.CSSProperties;
  className?: string;
}

export const Frame: React.FC<FrameProps> = ({ style, className, children }) => {
  const { isLoggedIn } = useSelector((state: AppState) => state.auth);
  if (isLoggedIn === undefined) return <>Loading</>;
  return (
    <S.Container style={style} className={className}>
      {isLoggedIn && <MenuBar />}
      <S.Content>
        <S.PageContent>{children}</S.PageContent>
      </S.Content>
      <Footer />
    </S.Container>
  );
};

Frame.displayName = 'Frame';
