import * as React from 'react';
import { matchPath, useHistory } from 'react-router';

import * as S from './_style';
import * as T from './_types';

export const MenuLink: React.FC<T.MenuLinkProps> = ({
  style,
  className,
  stretchLeft = false,
  stretchRight = false,
  to,
  children,
  isBeta = false,
}) => {
  const {
    location: { pathname },
  } = useHistory();

  const active: boolean = matchPath(pathname, to)?.isExact || false;

  // react-router's Link component passes all props down to the HTML a-tag. Therefore we need to use class names :-/
  return (
    <S.MenuLink
      style={style}
      className={`
        ${className}
        ${stretchRight && 'stretch-right'}
        ${stretchLeft && 'stretch-left'}
        ${active && 'active'}
      `}
      to={to}
    >
      {children}
      {isBeta ? <sup>&nbsp;beta</sup> : ''}
    </S.MenuLink>
  );
};

MenuLink.displayName = 'MenuLink';
