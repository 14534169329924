import React, { useState } from 'react';

import * as T from './_types';
import * as E from './_effects';

import { Card } from '../../../layout';
import { Button } from '../../../Button';
import { FileSelect } from '../../../inputs/FileSelect';
import { FormProvider, useForm } from 'react-hook-form';
import { Headline } from '../../../text';
import { ErrorMessage } from '../../../text';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, setHighlightedDatasets } from '../../../../store';
import { useLocation } from 'react-router-dom';
import { UploadParameters } from './UploadParameters/UploadParameters';

export type UploadFormValues = {
  file: FileList;
  biotype: string;
  columnSelect: { [key: number]: string };
  description: string;
  name: string;
  metadata_tags: string;
  genomeAssembly?: string;
  taxonomy?: string;
};

export const Upload: React.FC<T.UploadProps> = ({ style, className }) => {
  const form = useForm<UploadFormValues>();
  const { handleFiles, file, setFile } = E.useUpload();
  const { onSubmit, hideInvalid, disabled, setDisabled } = E.useFormSubmit(file);
  const { limitsUnknown, atUploadLimit } = E.useUploadLimits();
  const dispatch = useDispatch();

  const error = useSelector((state: AppState) =>
    file ? state.upload?.[file.name]?.error : false,
  );

  const datasets = useSelector((state: AppState) => state.dataSets.dataSets || []);
  const [warning, setWarning] = useState<string | null>(null);

  // Reset form when "Upload new file" button was clicked
  const location = useLocation();
  const { reset } = form;
  React.useEffect(() => {
    reset();
    setFile(undefined);
    setDisabled(false);
  }, [location, reset, setFile, setDisabled]);

  const onFileSelectChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    handleFiles(event);
    const file = event.currentTarget.files?.[0];
    if (file) {
      const existingDataset = datasets.find((dataset) => dataset.fileName === file.name);
      if (existingDataset) {
        setWarning(
          `File '${file.name}' already exists in the dataset list. Please consider renaming your file for better clarity.`,
        );
        dispatch(setHighlightedDatasets([existingDataset.id]));
      } else {
        setWarning(null);
        dispatch(setHighlightedDatasets([]));
      }
    }
    resetFields();
  };

  const resetFields = () => {
    const fields = Object.keys(form.getValues()).filter(
      (fieldName: string) => fieldName.indexOf('file') < 0,
    );

    fields.forEach((fieldName: string) => {
      if (fieldName === 'biotype') {
        form.setValue(fieldName, '1');
        return;
      }

      if (fieldName === 'columnSelect') {
        const value = {};

        for (let i = 0; i < 100; i++) {
          // @ts-ignore
          value[i] = '';
        }

        form.setValue(fieldName, value);
        return;
      }

      form.setValue(fieldName, undefined);
    });
  };

  const cardProps = {
    style,
    className,
  };

  if (limitsUnknown) {
    return (
      <Card {...cardProps}>
        <p>Loading...</p>
      </Card>
    );
  }

  if (atUploadLimit) {
    return (
      <Card {...cardProps}>
        <p>You reached your upload limit.</p>
        <p>Reach out to us to get more credits.</p>
      </Card>
    );
  }

  return (
    <Card {...cardProps}>
      <Headline margin={0} padding={4}>
        Add new dataset
      </Headline>

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} noValidate={hideInvalid}>
          <Headline design={'h2'} paddingLeft={0}>
            1. Add file and description{' '}
          </Headline>
          <FileSelect
            name="file"
            onChange={onFileSelectChange}
            required
            ref={form.register}
          />

          {warning && <ErrorMessage marginLeft={0}>{warning}</ErrorMessage>}

          <UploadParameters file={file} hideInvalid={hideInvalid} />

          {error && <ErrorMessage marginLeft={0}>{error}</ErrorMessage>}

          <Button
            label="Upload"
            type="submit"
            marginTop={4}
            marginBottom={2}
            disabled={disabled}
          />
        </form>
      </FormProvider>
    </Card>
  );
};

Upload.displayName = 'Upload';
