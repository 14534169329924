import Loadable from 'react-loadable';
import Loading from './_loading';

const loader = (props: any) =>
  Loadable(
    Object.assign(
      {
        loading: Loading,
        delay: 300,
        timeout: 10000,
      },
      props,
    ),
  );

export default loader;
