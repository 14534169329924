import React from 'react';

import * as S from './_style';
import * as T from './_types';
import { Link } from '../../../Link';

export const Footer: React.FC<T.FooterProps> = ({ style, className }) => (
  <S.Container style={style} className={className}>
    <div>
      <Link to="/SaaS-TandC" target="_blank" rel="noopener noreferrer">
        Terms of Use
      </Link>
      &nbsp;•&nbsp;
      <Link to="/privacy-policy">Data Privacy</Link>
      &nbsp;•&nbsp;
      <Link to="/legal" target="_blank" rel="noopener noreferrer">
        Legal Disclosure
      </Link>
      &nbsp;•&nbsp;<a href="mailto:support@knowing01.com">Need help?</a>
    </div>
  </S.Container>
);

Footer.displayName = 'Footer';
