import styled, { css } from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

import { mapPropToCss } from '../utils';

interface HeadlineProps extends SpaceProps {
  design?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  as?: string | null;
}

const h1CSS = css`
  font-size: 32px;
`;

const h2CSS = css`
  font-size: ${themeGet('fontSizes.3')};
`;

const h3CSS = css`
  font-size: ${themeGet('fontSizes.2')};
  font-weight: 600;
`;

const h4CSS = css`
  font-size: ${themeGet('fontSizes.0')};
`;

const h5CSS = css``;

export const Headline = styled.h1<HeadlineProps>`
  line-height: 1.2381em;
  font-weight: 400;
  padding-left: 24px;
  padding-right: 24px;
  margin-block-start: 24px;
  margin-block-end: 24px;
  color: ${themeGet('colors.text')};
  ${mapPropToCss('design', {
    h1: h1CSS,
    h2: h2CSS,
    h3: h3CSS,
    h4: h4CSS,
    h5: h5CSS,
  })};
  ${space}
`;

Headline.defaultProps = {
  design: 'h1',
  as: null,
};
