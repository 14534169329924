import styled, { css } from 'styled-components';
import { Link as UnstyledLink } from 'react-router-dom';
import { themeGet } from '@styled-system/theme-get';

export const linkCSS = css`
  color: ${themeGet('colors.text')};
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  white-space: nowrap;
  text-decoration: none;
  border-bottom: 3px solid #eba400;

  :hover {
    border-bottom: 3px solid #eba400;
  }
`;

export const Link = styled(UnstyledLink)`
  ${linkCSS}
`;
