import React, { useEffect } from 'react';

import * as S from './_style';
import * as T from './_types';

import { DatasetStatus } from '../../DatasetStatus';
import styled from 'styled-components';
import { DatasetItemStatus } from '../..';
import { DatasetFavorite } from './DatasetSetFavorite';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, clearHighlightedDatasets } from '../../../../../store';

const WarningImage = styled.div`
  width: 35px;
  margin: auto;
  padding-left: 3px;
  padding-right: 3px;
`;

export const DatasetItem: React.FC<T.DatasetItemProps> = ({
  style,
  className,
  fileName,
  name,
  selected = false,
  id,
  experiment_id,
  statistics,
  status,
  favorite,
}) => {
  const dispatch = useDispatch();
  const highlightedDatasets = useSelector(
    (state: AppState) => state.dataSets.highlightedDatasets || [],
  );
  const isHighlighted = highlightedDatasets.includes(id);

  useEffect(() => {
    dispatch(clearHighlightedDatasets());
  }, [dispatch]);

  useEffect(() => {
    if (isHighlighted) {
      const timer = setTimeout(() => {
        dispatch(clearHighlightedDatasets());
      }, 9000);

      return () => clearTimeout(timer);
    }
  }, [isHighlighted, dispatch]);

  return (
    <S.Boxed selected={selected} isHighlighted={isHighlighted}>
      <DatasetFavorite
        experimentId={experiment_id}
        filled={favorite}
        show={status === DatasetItemStatus.INTEGRATED}
      />
      <S.Button style={style} className={className} to={`/datasets/${id}`}>
        <S.Title>{name || fileName}</S.Title>
        {statistics &&
        status === DatasetItemStatus.INTEGRATED &&
        statistics.data_linked_count === 0 ? (
          <WarningImage>
            <img src="/images/attention.svg" width="25px" alt="" />
          </WarningImage>
        ) : (
          ''
        )}
      </S.Button>
      <DatasetStatus id={id} reduced={false} />
    </S.Boxed>
  );
};

DatasetItem.displayName = 'DatasetItem';
