import { AuthState, AuthAction } from './auth';
import { DataSetsState, DataSetsAction } from './data-sets';
import { UploadState, UploadAction } from './upload';
import { ExperimentsState, ExperimentAction } from './experiments';
import { User } from './user';
import { UserRequestState } from './userRequests';
import { SearchAction, SearchResultState } from './search';
import { AnnotateAction, AnnotateResultState } from './annotate';

export type AnyAction =
  | AuthAction
  | DataSetsAction
  | UploadAction
  | ExperimentAction
  | SearchAction
  | AnnotateAction;

export interface AppState {
  highlightedDatasets: string[];
  experiments: ExperimentsState;
  selection: DatasetSelection[];
  upload: UploadState;
  auth: AuthState;
  dataSets: DataSetsState;
  requests: UserRequestState;
  search: SearchResultState;
  annotate: AnnotateResultState;
  user: User;
}

export enum ExperimentOverlapType {
  INTERSECT = 0,
  SUBTRACT = 1,
}

export enum ExperimentCutoffType {
  NONE = 0,
  LAX = 1,
  STRINGENT = 2,
}

export enum ViewType {
  Table = 'TABLE',
  Grid = 'GRID',
}

export enum DataViewType {
  Full = 'FULL',
  Minimal = 'MINIMAL',
}

export interface DatasetSelection {
  id: string;
  name: string;
  selectionId: string;
  overlapType: ExperimentOverlapType;
  cutoffType: ExperimentCutoffType;
  isSet: boolean;
  viewType: ViewType;
  dataSetViewType: DataViewType;
  data: any;
  isLoadingData: boolean;
  hasLoadingError: boolean;
}
