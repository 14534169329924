import React, { ChangeEventHandler, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import * as S from './_style';
import * as T from './_types';
import * as E from './_effects';

import { DatasetList } from './DatasetList';
import { DatasetDetails } from './DatasetDetails';
import { DatasetItem } from './DatasetList/DatasetItem';
import { useLoginRequirement } from '../../utils';
import { Headline } from '../../text';
import { Card } from '../../layout';
import { Upload } from './Upload/Upload';
import { Button, ButtonDesign } from '../../Button';
import { InputSearchFilter, WellAlignedDiv } from '../../inputs';
import styled from 'styled-components';
import { IconFilter } from '../../icons';

export interface Option {
  value?: string;
  label?: string;
  description: string;
}

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonUpload = styled.div`
  margin: auto 18px;
`;

export const Datasets: React.FC<T.DatasetsProps> = ({ style, className }) => {
  useLoginRequirement();
  const { datasets, selection, uploadFileUsage, uploadFileLimit } = E.useDatasets();
  const history = useHistory();

  let [inputValue, setInputValue] = useState<string>('');
  let search: string[] = inputValue.toLowerCase().split(' ');

  const handleTextInput: ChangeEventHandler<{ value: string }> = ({
    target: { value: textInputValue },
  }) => {
    search = inputValue.toLowerCase().split(' ');
    setInputValue(textInputValue);
  };

  // const { userShowOnlyFavorites } = useSelector((state: AppState) => state.userShowOnlyFavorites,);

  const [upRankFavorites, setSortFavoritesTop] = useState(false);

  return (
    <S.Container style={style} className={className}>
      <S.Left>
        <Card roundedBorders={'none'}>
          <FlexContainer>
            <Headline margin={0} paddingTop={28} paddingRight={18} paddingBottom={28}>
              Datasets
            </Headline>
            {uploadFileUsage !== uploadFileLimit && (
              <ButtonUpload>
                <Button
                  onClick={() => history.push('/datasets/upload')}
                  design={ButtonDesign.HIGHLIGHT}
                >
                  Upload new dataset
                </Button>
              </ButtonUpload>
            )}
          </FlexContainer>
          <InputSearchFilter
            placeholder={'Search in name, description and tags'}
            value={inputValue}
            onChange={handleTextInput}
          />
          <WellAlignedDiv>
            <IconFilter />
            <Button
              onClick={() => setSortFavoritesTop(!upRankFavorites)}
              design={ButtonDesign.LINK}
            >
              {upRankFavorites ? 'Show all' : 'Show starred'}
            </Button>
          </WellAlignedDiv>
          {
            <DatasetList>
              {datasets &&
                datasets
                  .filter((dataset) => {
                    if (search[0] === '') return true;
                    if (
                      search.filter((word) => {
                        // noinspection TypeScriptValidateTypes
                        return (
                          dataset.name.toLocaleLowerCase().includes(word) ||
                          dataset.description.toLocaleLowerCase().includes(word) ||
                          dataset.metadata_tags?.toLocaleLowerCase().includes(word) ||
                          dataset.fileName.toLocaleLowerCase().includes(word)
                        );
                      }).length === search.length
                    )
                      return true;
                  })
                  .filter(
                    (dataset) =>
                      !upRankFavorites || (dataset.favorite && upRankFavorites),
                  )
                  .map((data) => (
                    <DatasetItem
                      key={data.id}
                      {...data}
                      selected={data.id === selection}
                    />
                  ))
                  .reverse()}
            </DatasetList>
          }
        </Card>
      </S.Left>

      <S.Right>
        <Switch>
          <Route path="/datasets/upload" component={Upload} />
          <Route path="/datasets/:fileId" component={DatasetDetails} />
        </Switch>
      </S.Right>
    </S.Container>
  );
};

Datasets.displayName = 'Datasets';
