import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv, faFile } from '@fortawesome/free-solid-svg-icons';

import * as S from './_style';

import { Box } from '../../../layout/Box';
import { BoxProps } from '../../../layout/Box/_types';

export interface SingleFileProps extends BoxProps {
  style?: React.CSSProperties;
  className?: string;
  fileName: string;
  fileType?: 'txt' | 'csv' | 'unknown';
}

export const SingleFile: React.FC<SingleFileProps> = ({
  style,
  className,
  fileName,
  fileType = 'unknown',
  ...styleProps
}) => {
  const icon = {
    unknown: faFile,
    txt: faFile,
    csv: faFileCsv,
  }[fileType];

  return (
    <Box
      flexGrow={1}
      style={style}
      className={className}
      display="inline-flex"
      flexDirection="column"
      alignItems="center"
      alignContent="center"
      borderRadius={0}
      padding={1}
      paddingTop={2}
      backgroundColor="grey90"
      color="text"
      {...styleProps}
    >
      <FontAwesomeIcon size="2x" icon={icon} />
      <S.Label>{fileName}</S.Label>
    </Box>
  );
};

SingleFile.displayName = 'File';
