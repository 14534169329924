import React from 'react';

const LoadPdf: React.FC = () => {
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <iframe
        src="/pdfFile/k01TaC_SaaS_20230626_EN.pdf"
        style={{ width: '100%', height: '100%', border: 'none' }}
        title="Terms and Conditions"
      ></iframe>
    </div>
  );
};

export default LoadPdf;
