import { call, put, select, takeEvery, delay } from 'redux-saga/effects';

import { AppState } from './_types';
import { readExperiments } from '../services/api';
import {
  ExperimentCutoff,
  ExperimentRaw,
  ExperimentStatistics,
} from '../components/CellMap';
import { Biotype } from '../components/Pages/Datasets/Upload/_types';

// Action Types
export const FETCH_EXPERIMENT = 'FETCH_EXPERIMENT';
export const FETCH_EXPERIMENT_START = 'FETCH_EXPERIMENT_START';
export const FETCH_EXPERIMENT_FAIL = 'FETCH_EXPERIMENT_FAIL';
export const FETCH_EXPERIMENT_SUCCESS = 'FETCH_EXPERIMENT_SUCCESS';
export const RESET_EXPERIMENTS = 'RESET_EXPERIMENTS';

// Actions
export const fetchExperimentListStart = () => ({
  type: FETCH_EXPERIMENT_START,
});

export const fetchExperimentListStartFail = (errorMessage: string) => ({
  type: FETCH_EXPERIMENT_FAIL,
  payload: { errorMessage },
});

export const fetchExperimentListStartSuccess = (experimentList: Experiment[]) => ({
  type: FETCH_EXPERIMENT_SUCCESS,
  payload: { experimentList },
});

export const fetchExperimentList = () => ({
  type: FETCH_EXPERIMENT,
});

export const resetExperiments = () => ({
  type: RESET_EXPERIMENTS,
});

export type ExperimentAction =
  | ReturnType<typeof fetchExperimentListStart>
  | ReturnType<typeof fetchExperimentListStartFail>
  | ReturnType<typeof fetchExperimentListStartSuccess>
  | ReturnType<typeof fetchExperimentList>;

// State
export interface ExperimentsState {
  experimentList: Experiment[] | null;
  isLoading: boolean;
  hasError: boolean;
  errorMessage: string | null;
}

export interface Experiment {
  id: string;
  name: string;
  description: string;
  biotype: Biotype;
  cutoff_lax: ExperimentCutoff;
  cutoff_stringent: ExperimentCutoff;
  statistics: ExperimentStatistics;
  favorite: boolean;
  has_fake_significance: boolean;
  metadata_tags: string[] | null;
}

const defaultState: ExperimentsState = {
  experimentList: null,
  isLoading: false,
  hasError: false,
  errorMessage: null,
};

// Reducer
const reducer = (
  state: ExperimentsState = defaultState,
  action: ExperimentAction,
): ExperimentsState => {
  switch (action.type) {
    case FETCH_EXPERIMENT_START:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_EXPERIMENT_FAIL: {
      const {
        payload: { errorMessage },
      } = action as ReturnType<typeof fetchExperimentListStartFail>;
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage,
      };
    }
    case FETCH_EXPERIMENT_SUCCESS: {
      const {
        payload: { experimentList },
      } = action as ReturnType<typeof fetchExperimentListStartSuccess>;
      return {
        ...state,
        isLoading: false,
        hasError: false,
        errorMessage: null,
        experimentList,
      };
    }
    case RESET_EXPERIMENTS:
      return {
        ...defaultState,
      };
    default:
      return state;
  }
};

export default reducer;

// Sagas
function* handleFetchExperiments() {
  const isLoading = yield select((state: AppState) => state?.experiments?.isLoading);

  if (isLoading) {
    return;
  }

  yield put(fetchExperimentListStart());
  const idToken = yield select((state: AppState) => state?.auth?.idToken);
  let experimentList: Experiment[] = [];
  try {
    const rawResponse: ExperimentRaw[] = yield call(readExperiments, idToken);
    experimentList =
      (rawResponse.map(({ biotype, ...rest }) => ({
        biotype: parseInt(biotype, 10),
        ...rest,
      })) as Experiment[]) || [];
  } catch (error) {
    yield delay(400);
    yield put(fetchExperimentListStartFail('Could not fetch experiment list'));
    return;
  }
  yield put(fetchExperimentListStartSuccess(experimentList));
}

function* watchFetchExperiments() {
  yield takeEvery(FETCH_EXPERIMENT, handleFetchExperiments);
}

export const experimentSagas = [watchFetchExperiments()];
