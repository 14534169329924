import React from 'react';

import { Dataset } from '../_types';
import { Headline } from '../../../text';
import { ExperimentCutoffType } from '../../../../store';
import { DatasetCutoffForm } from './DatasetCutoffForm';
import { ColumnTag } from '../Upload/FileColumnSelector/_data';
import { SignificanceType } from '../../../CellMap';

type DatasetCutoffProps = {
  dataSet: Dataset;
};

export const DatasetCutoff = ({ dataSet }: DatasetCutoffProps) => {
  if (dataSet.has_fake_significance) {
    if (dataSet.columnSelect.hasOwnProperty(ColumnTag.Score.valueOf())) {
      dataSet.cutoff_lax.type = SignificanceType.MIMIC_SCORE;
      dataSet.cutoff_stringent.type = SignificanceType.MIMIC_SCORE;

      dataSet.cutoff_lax.cutoff = Math.round(dataSet.cutoff_lax.cutoff * 100) / 100;
      dataSet.cutoff_stringent.cutoff =
        Math.round(dataSet.cutoff_stringent.cutoff * 100) / 100;
    }

    if (dataSet.columnSelect.hasOwnProperty(ColumnTag['Effect size measure'].valueOf())) {
      dataSet.cutoff_lax.type = SignificanceType.MIMIC_EFFECT_SIZE;
      dataSet.cutoff_stringent.type = SignificanceType.MIMIC_EFFECT_SIZE;

      dataSet.cutoff_lax.cutoff = Math.round(dataSet.cutoff_lax.cutoff * 100) / 100;
      dataSet.cutoff_stringent.cutoff =
        Math.round(dataSet.cutoff_stringent.cutoff * 100) / 100;
    }

    dataSet.available_cutoffs = [];
    dataSet.available_cutoffs.push(dataSet.cutoff_lax);
    dataSet.available_cutoffs.push(dataSet.cutoff_stringent);
  }

  return (
    <>
      <Headline design={'h2'}>Significance settings</Headline>

      <p>
        You can define a "stringent" and a "lax" cutoff to make use of two lists of
        relevant observations.
        {!dataSet.has_fake_significance && ' Choose from a list of significance cutoffs.'}
      </p>

      {!!(dataSet.available_cutoffs.length || dataSet.has_fake_significance) && (
        <DatasetCutoffForm
          cutoffType={ExperimentCutoffType.STRINGENT}
          experimentId={dataSet.experiment_id}
          cutoffs={dataSet.available_cutoffs}
          selectedCutoff={dataSet.cutoff_stringent}
          allowEdit={!dataSet.has_fake_significance}
        />
      )}

      {!!(dataSet.available_cutoffs.length || dataSet.has_fake_significance) && (
        <DatasetCutoffForm
          cutoffType={ExperimentCutoffType.LAX}
          experimentId={dataSet.experiment_id}
          cutoffs={dataSet.available_cutoffs}
          selectedCutoff={dataSet.cutoff_lax}
          allowEdit={!dataSet.has_fake_significance}
        />
      )}
    </>
  );
};
