import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Left = styled.div`
  flex-basis: 360px;
  flex-shrink: 0;

  margin-right: ${themeGet('space.3')};

  @media (min-width: 1024px) {
    flex-basis: 480px;
  }
`;

export const Right = styled.div`
  height: calc(97vh - var(--menu-bar-height) - var(--footer-height));
  flex-grow: 1;
  overflow-y: scroll;
`;
