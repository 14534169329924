import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  z-index: 10;
  height: var(--menu-bar-height);
  border-bottom: #eee 1px solid;
  width: 100%;
  justify-content: space-between;
  display: flex;
  background: white;
  img {
    padding-right: 25px;
    padding-left: 25px;
    margin-bottom: 3px;
    margin-top: 5px;
  }
`;
