import * as React from 'react';

export const useFileSelect = (onChange?: React.ChangeEventHandler<HTMLInputElement>) => {
  const [file, setFile] = React.useState<File>();
  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const fileList = event.currentTarget.files;

    if (fileList === null) {
      setFile(undefined);
    } else {
      setFile(fileList[0]);
    }
    onChange && onChange(event);
  };

  return {
    file: file,
    handleFileChange,
    setFile: setFile,
  };
};
