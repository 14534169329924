import React from 'react';

import * as S from './_style';
import * as T from './_types';


export const DatasetList: React.FC<T.DatasetListProps> = ({
  style,
  className,
  children,
}) =>(
  <S.Container
    style={style}
    className={className}
  >
    {children}
  </S.Container>
);

DatasetList.displayName = 'DatasetList';