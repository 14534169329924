import * as React from 'react';
import { createGlobalStyle } from 'styled-components';
import { linkCSS } from '../Link';
import { themeGet } from '@styled-system/theme-get';

const GlobalStyleSC = createGlobalStyle<{ noPadding: boolean }>`
  :root {
    --menu-bar-height: 60px;
    --footer-height: 33px;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    scroll-behavior: smooth;
    scroll-padding-top: calc(var(--menu-bar-height) + 1rem);
  }

  body {
    scroll-padding-top: 5rem;
    height: auto;
    min-height: 100%;
    color: ${themeGet('colors.text')};
    background: ${themeGet('colors.background')};
    font-family: ${themeGet('fonts.default')};
  }

  a {
    ${linkCSS}
  }

  p, ol, ul {
    padding-left: 24px;
    padding-right: 24px;
    margin-block-start: 24px;
    margin-block-end: 24px;
    font-size: ${themeGet('fontSizes.2')};
  }

  small {
    font-size: ${themeGet('fontSizes.0')};
  }

  ol, ul {
    padding-left: 40px;
    margin-block-end: 0px;
    margin-block-start: 0px;
  }

  hr {
    height: 1px;
    margin: ${themeGet('space.4')} 0;
    border: 0;

    background-color: ${themeGet('colors.grey90')};
  }


  #root {
    min-height: 100vh;
    padding: ${(props) => (props.noPadding ? '0' : '8px')};
    box-sizing: border-box;
  }


  @font-face {
    font-family: Nunito;
    src: url('/fonts/nunito-semibolditalic.woff2') format('woff2'),
         url('/fonts/nunito-semibolditalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: Nunito;
    src: url('/fonts/nunito-semibold.woff2') format('woff2'),
         url('/fonts/nunito-semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: Nunito;
    src: url('/fonts/nunito-regular.woff2') format('woff2'),
         url('/fonts/nunito-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: Nunito;
    src: url('/fonts/nunito-lightitalic.woff2') format('woff2'),
         url('/fonts/nunito-lightitalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: Nunito;
    src: url('/fonts/nunito-light.woff2') format('woff2'),
         url('/fonts/nunito-light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: Nunito;
    src: url('/fonts/nunito-italic.woff2') format('woff2'),
         url('/fonts/nunito-italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: Nunito;
    src: url('/fonts/nunito-extralightitalic.woff2') format('woff2'),
         url('/fonts/nunito-extralightitalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
  }

  @font-face {
    font-family: Nunito;
    src: url('/fonts/nunito-extralight.woff2') format('woff2'),
         url('/fonts/nunito-extralight.woff') format('woff');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: Nunito;
    src: url('/fonts/nunito-extrabolditalic.woff2') format('woff2'),
         url('/fonts/nunito-extrabolditalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
  }

  @font-face {
    font-family: Nunito;
    src: url('/fonts/nunito-extrabold.woff2') format('woff2'),
         url('/fonts/nunito-extrabold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: Nunito;
    src: url('/fonts/nunito-bolditalic.woff2') format('woff2'),
         url('/fonts/nunito-bolditalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: Nunito;
    src: url('/fonts/nunito-bold.woff2') format('woff2'),
         url('/fonts/nunito-bold.woff') format('woff');
    font-weight: 600;
    font-style: italic;
  }

  form {
    padding-left: 24px;
    padding-right: 24px;
  }

`;

export const GlobalStyle: React.FC<{ noPadding?: boolean }> = ({
  children,
  noPadding = false,
}) => (
  <React.Fragment>
    <GlobalStyleSC noPadding={noPadding} />
    {children}
  </React.Fragment>
);
