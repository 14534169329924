import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const HiddenSelect = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
`;

export const Label = styled.div`
  padding-right: 1.5rem;
  user-select: none;
`;

export const Chevron = styled(FontAwesomeIcon)`
  color: #ccc;
  position: absolute;
  right: .5rem;
  top: calc(50% - .4375rem);
  transition: color .3s ease-out;
`;

const cssContainerEnabled = css`
  ${HiddenSelect} {
    cursor: pointer;
  }

  &:hover {
    outline-color: #777;

    ${Chevron} {
      color: #333;
    }
  }
`;

export const Container = styled.div<{ disabled: boolean }>`
  outline: 1px solid #ccc;
  position: relative;
  padding: .25rem .25rem .25rem .5rem;
  transition: outline-color .3s ease-out;
  
  ${({ disabled }) => disabled ? '' : cssContainerEnabled};
`;
