import { put, takeEvery, call, take, race, select } from 'redux-saga/effects';

import { readJoinedExperimentData } from '../services/api';
import {
  DatasetSelection,
  DataViewType,
  ExperimentCutoffType,
  ExperimentOverlapType,
  ViewType,
} from './_types';
import {
  ExperimentDataResponse,
  ExperimentQuery,
  ExperimentRaw,
} from '../components/CellMap';
import { assignAtIndex, selectionStateToQuery } from '../utils';
import shapeDataList, { DataListData } from '../utils/shape-data-list';

// Action types
export const SELECT_EXPERIMENT = 'SELECT_EXPERIMENT';
export const SELECT_OPERATION = 'SELECT_OPERATION';
export const SELECT_CUTOFF = 'SELECT_CUTOFF';
export const CONFIRM_EXPERIMENT_SELECTION = 'CONFIRM_EXPERIMENT_SELECTION';
export const FETCH_EXPERIMENT_DATA_START = 'FETCH_EXPERIMENT_DATA_START';
export const FETCH_EXPERIMENT_DATA_SUCCESS = 'FETCH_EXPERIMENT_DATA_SUCCESS';
export const FETCH_EXPERIMENT_DATA_FAIL = 'FETCH_EXPERIMENT_DATA_FAIL';
export const ABORT_FETCH_EXPERIMENT_DATA = 'ABORT_FETCH_EXPERIMENT_DATA';
export const EDIT_LAST_SELECTION = 'EDIT_LAST_SELECTION';
export const SET_DATA_VIEW_TYPE = 'SET_LIST_VIEW_TYPE';
export const SET_DATASET_VIEW_TYPE = 'SET_DATASET_VIEW_TYPE';

// Actions
export const resetSelectionState = () => ({
  type: 'RESET_SELECTION_STATE',
});

export const selectExperiment = (index: number, id: string, name: string) => ({
  type: SELECT_EXPERIMENT,
  payload: { index, id, name },
});

export const selectOperation = (index: number, overlapType: ExperimentOverlapType) => ({
  type: SELECT_OPERATION,
  payload: { index, overlapType },
});

export const selectCutoff = (index: number, cutoffType: ExperimentCutoffType) => ({
  type: SELECT_CUTOFF,
  payload: { index, cutoffType },
});

export const editLastSelection = () => ({
  type: EDIT_LAST_SELECTION,
});

export const confirmExperimentSelection = () => ({
  type: CONFIRM_EXPERIMENT_SELECTION,
});

export const fetchExperimentData = (
  accessToken: string,
  selection: DatasetSelection[],
  experimentList: ExperimentRaw[],
  index: number,
) => ({
  type: FETCH_EXPERIMENT_DATA_START,
  payload: {
    selection,
    accessToken,
    experimentList,
    index,
  },
});

export const abortFetchExperimentData = () => ({
  type: ABORT_FETCH_EXPERIMENT_DATA,
});

const fetchExperimentDataSuccess = (dataList: DataListData, index: number) => ({
  type: FETCH_EXPERIMENT_DATA_SUCCESS,
  payload: { dataList, index },
});

const fetchExperimentDataFail = (index: number) => ({
  type: FETCH_EXPERIMENT_DATA_FAIL,
  payload: { index },
});

type SelectorBoxAction =
  | ReturnType<typeof selectExperiment>
  | ReturnType<typeof selectOperation>
  | ReturnType<typeof editLastSelection>
  | ReturnType<typeof confirmExperimentSelection>
  | ReturnType<typeof fetchExperimentDataSuccess>
  | ReturnType<typeof fetchExperimentDataFail>
  | ReturnType<typeof fetchExperimentData>
  | ReturnType<typeof abortFetchExperimentData>;

export const setDataViewType = (index: number, viewType: ViewType) => ({
  type: SET_DATA_VIEW_TYPE,
  payload: { index, viewType },
});

export const setDataSetViewType = (index: number, dataSetViewType: DataViewType) => ({
  type: SET_DATASET_VIEW_TYPE,
  payload: { index, dataSetViewType },
});

// State
const defaultState: DatasetSelection[] = [
  {
    id: '',
    selectionId: '',
    name: '',
    overlapType: ExperimentOverlapType.INTERSECT,
    cutoffType: ExperimentCutoffType.STRINGENT,
    isSet: false,
    viewType: ViewType.Table,
    dataSetViewType: DataViewType.Minimal,
    data: null,
    isLoadingData: false,
    hasLoadingError: false,
  },
];

type SelectionAction = {
  type: string;
  payload?: any;
} & SelectorBoxAction;

const reducer = (state = defaultState, action: SelectionAction) => {
  const {
    type,
    payload: {
      index,
      viewType,
      id,
      overlapType,
      cutoffType,
      name,
      dataList,
      dataSetViewType,
    } = {},
  } = action;

  switch (type) {
    case EDIT_LAST_SELECTION:
      return state
        .filter((_, currentIndex, { length }) => currentIndex < length - 1)
        .map(
          assignAtIndex(state.length - 2, {
            isSet: false,
            data: null,
            isLoadingData: false,
            hasLoadingError: false,
          }),
        );
    case SET_DATA_VIEW_TYPE:
      return state.map(assignAtIndex(index, { viewType }));
    case SELECT_EXPERIMENT:
      return state.map(assignAtIndex(index, { id, name }));
    case SELECT_OPERATION:
      return state.map(assignAtIndex(index, { overlapType }));
    case SELECT_CUTOFF:
      return state.map(assignAtIndex(index, { cutoffType }));
    case CONFIRM_EXPERIMENT_SELECTION:
      return [
        ...state.slice(0, state.length - 1),
        ...state.slice(state.length - 1),
        ...defaultState,
      ]
        .map((selection, stateIndex, { length }) =>
          stateIndex === length - 2 ? { ...selection, isSet: true } : selection,
        )
        .map((selection, index, selections) => {
          if (!selection.isSet) return selection;
          return {
            ...selection,
            selectionId: selections
              .slice(0, index + 1)
              .map((selection) => [selection.overlapType, selection.id].filter(Boolean))
              .flat()
              .join('.'),
          };
        });
    case FETCH_EXPERIMENT_DATA_START:
      return state.map(
        assignAtIndex(index, { isLoadingData: true, hasLoadingError: false }),
      );
    case FETCH_EXPERIMENT_DATA_SUCCESS:
      return state.map(assignAtIndex(index, { isLoadingData: false, data: dataList }));
    case FETCH_EXPERIMENT_DATA_FAIL:
      return state.map(
        assignAtIndex(
          index,
          { isLoadingData: false, data: null, hasLoadingError: true },
          ({ isSet }: { isSet: boolean }) => !!isSet,
        ),
      );
    case SET_DATASET_VIEW_TYPE:
      return state.map(assignAtIndex(index, { dataSetViewType }));
    case 'RESET_SELECTION_STATE': {
      return defaultState;
    }
    default:
      return state;
  }
};

export default reducer;

// Sagas
function* requestExperimentData(action: ReturnType<typeof fetchExperimentData>) {
  const {
    payload: { selection, experimentList, index },
  } = action;

  const query: ExperimentQuery = selectionStateToQuery(selection as DatasetSelection[]);
  const idToken: string = yield select((state) => state.auth?.idToken || '');

  try {
    const [apiResponse]: [ExperimentDataResponse] = yield race([
      call(readJoinedExperimentData, idToken, query),
      take(ABORT_FETCH_EXPERIMENT_DATA),
      take(EDIT_LAST_SELECTION),
    ]);

    if (!!apiResponse) {
      const dataList = shapeDataList(apiResponse, experimentList);
      yield put(fetchExperimentDataSuccess(dataList, index));
    } else {
      yield put(fetchExperimentDataFail(index));
    }
  } catch (e) {
    yield put(fetchExperimentDataFail(index));
  }
}

function* watchExperimentFetch() {
  yield takeEvery(FETCH_EXPERIMENT_DATA_START, requestExperimentData);
}

export const selectorBoxSagas = [watchExperimentFetch()];
