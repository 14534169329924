import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 10;

  box-sizing: border-box;
  height: var(--footer-height);
  width: 100%;
  padding: 10px;

  background: white;
  text-align: center;
  font-size: ${themeGet('fontSizes.0')};
  border-top: #eee 1px solid;
`;

export const Links = styled.div`
  margin: 0 auto 8px;
  font-size: ${themeGet('fontSizes.0')};

  > a:nth-child(n + 2) {
    margin-left: 16px;
  }
`;
