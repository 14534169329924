import * as React from 'react';

// @ts-ignore
const Loader = ({ error, timedOut, pastDelay }) => {
  if (error) {
    return <div>Error!</div>;
  } else if (timedOut) {
    return <div>Timed Out</div>;
  } else if (pastDelay) {
    return <div>Past Delay</div>;
  }
  return null;
};

export default Loader;
