import { BlankIcon12by12, StarIcon } from '../../../../icons';
import { useDispatch } from 'react-redux';
import React from 'react';
import { Button, ButtonDesign } from '../../../../Button';
import { toggleFavorite } from '../../../../../store';

type DatasetFavoriteProps = {
  experimentId: string;
  filled: boolean;
  show: boolean;
};

export const DatasetFavorite = ({ experimentId, filled, show }: DatasetFavoriteProps) => {
  const dispatch = useDispatch();
  const fill = filled ? true : false;
  const onSubmit = (experimentId: string) => {
    dispatch(toggleFavorite(experimentId));
  };

  return (
    <Button
      design={ButtonDesign.LIGHT}
      onClick={() => onSubmit(experimentId)}
      disabled={!show}
    >
      {show ? <StarIcon filled={fill}></StarIcon> : <BlankIcon12by12 />}
    </Button>
  );
};
