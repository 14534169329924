import * as React from 'react';
import Papa, { GuessableDelimiters } from 'papaparse';

import { usePromise } from '../../../../utils';

export const useCSV = (lines?: string[]) => {
  if (!lines) return { error: [], data: undefined, meta: {} };

  return Papa.parse<any>(lines.join('\n'), {
    delimitersToGuess: [
      '\t',
      ',',
      '|',
      ';',
      Papa.RECORD_SEP,
      Papa.UNIT_SEP,
      ' ' as GuessableDelimiters,
    ],
  });
};

export const useLinesFromFile = (file: File, lineCount: number = 2) => {
  const [lines, setLines] = React.useState<string[] | undefined>();
  const [parseError, setParseError] = React.useState<Error | undefined>();

  const readData = React.useCallback(
    // 3000 seems like a sensible value unless columns are extremely long
    (): Promise<string> =>
      new Promise((resolve, reject) => {
        const dataBlob = file.slice(0, 3000, 'text/plain');
        const reader = new FileReader();
        reader.addEventListener('loadend', (event) => {
          resolve((event?.target?.result as string) || '');
        });
        reader.addEventListener('error', (event) => {
          reject(event);
        });
        reader.readAsText(dataBlob);
      }),
    [file],
  );

  const [read, { error: promiseError, data, loading }] = usePromise<string>(readData);
  React.useEffect(() => void read(), [read]);

  React.useEffect(() => {
    if (!data) return;
    try {
      let lines = data.split(/\r?\n/).splice(0, lineCount);
      if (lines.length === 1) {
        setLines(data.split('\r').splice(0, lineCount));
      } else {
        setLines(lines);
      }
      setParseError(undefined);
    } catch (err) {
      setParseError(err);
    }
  }, [data, setLines, setParseError, lineCount]);

  const error: Error | undefined = parseError || promiseError || undefined;

  return {
    loading,
    error,
    lines,
  };
};
