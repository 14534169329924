import styled, { css } from 'styled-components';
import { layout, gridArea, space, compose } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

import * as T from './_types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Container = styled.div<T.StyleProps>`
  display: inline-block;
  position: relative;
  margin-bottom: ${themeGet('space.2')};
  ${compose(layout, gridArea, space)};
`;

export const Select = styled.select`
  appearance: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
  margin: 0;
  padding: 0;

  :disabled {
    cursor: not-allowed;
  }
`;

export const Border = styled.div<{ hideInvalid: boolean }>`
  padding: .5rem 1.75rem .5rem .25rem;
  font-size: 1rem;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${themeGet('colors.grey70')};
  border-radius: ${themeGet('radii.small')};
  resize: vertical;
  white-space: nowrap;

  ${Select}:required:valid ~ & {

  }

  ${Select}:invalid ~ & {
    ${props => !props.hideInvalid && css`
      color: ${themeGet('colors.error')};
      border-color:  ${themeGet('colors.error')};
      box-shadow: none;
    `}
  }

  ${Select}:focus ~ & {
    outline: 0;
    border-color: ${themeGet('colors.grey10')};
  }

  ${Select}:disabled ~ & {

  }

  @media (hover: hover) {
    ${Select}:hover ~ & {

    }
  }
`;

export const Chevron = styled(FontAwesomeIcon)`
  color: #ccc;
  position: absolute;
  right: .5rem;
  top: calc(50% - .4375rem);
  transition: color .3s ease-out;
`;
