import {
  AnnotateQueryParameter,
  AnnotateResultRequest,
  SearchResult,
} from '../components/CellMap';
import { call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { AppState, ExperimentCutoffType } from './_types';
import { fetchAnnotateResults } from '../services/api';
import { dataSetsLoad } from './data-sets';
import { SearchResultState } from './search';

// Actions
export const loadAnnotate = (dataset_id: string, cutoff_type: ExperimentCutoffType) => ({
  type: 'GET_ANNOTATE_RESULT',
  payload: {
    dataset_id,
    cutoff_type,
  },
});

export const annotateLoadSuccess = (searchResult: SearchResult) => ({
  type: 'ANNOTATE_LOAD_SUCCESS',
  payload: { searchResult },
});

export const annotateLoadError = (errorMessage: string) => ({
  type: 'ANNOTATE_LOAD_ERROR',
  payload: { errorMessage },
});

export const resetAnnotateResult = () => ({
  type: 'RESET_SEARCH_RESULT',
});

export type AnnotateAction =
  | ReturnType<typeof loadAnnotate>
  | ReturnType<typeof annotateLoadSuccess>
  | ReturnType<typeof annotateLoadError>;

export interface AnnotateResultState extends SearchResultState {}

export const defaultState: AnnotateResultState = {
  loading: false,
  searchResult: {
    search_string: '',
    type: '',
    dataset_id: '',
    cutoff_type: '',
    result: {},
    status: 0,
    dataset_meta_tags: {},
  },
};

const reducer = (
  state: AnnotateResultState = defaultState,
  action: AnnotateAction,
): AnnotateResultState => {
  switch (action.type) {
    case 'GET_ANNOTATE_RESULT':
      const {
        payload: { dataset_id, cutoff_type },
      } = action as ReturnType<typeof loadAnnotate>;
      return {
        ...state,
        loading: true,
      };
    case 'ANNOTATE_LOAD_SUCCESS': {
      const {
        payload: { searchResult },
      } = action as ReturnType<typeof annotateLoadSuccess>;
      try {
        searchResult.dataset_meta_tags = {};
      } catch (e) {}
      return {
        ...state,
        errorMessage: undefined,
        loading: false,
        searchResult,
      };
    }
    case 'ANNOTATE_LOAD_ERROR': {
      const {
        payload: { errorMessage },
      } = action as ReturnType<typeof annotateLoadError>;
      return {
        ...state,
        loading: false,
        errorMessage,
      };
    }
    case 'RESET_ANNOTATE_RESULT':
      return defaultState;

    default:
      return state;
  }
};
export default reducer;

function* handleAnnotateLoad(action: ReturnType<typeof loadAnnotate>) {
  let searchResult = yield select((state: AppState) => state.annotate);
  searchResult.loading = true;
  searchResult.searchResult = {
    request: {} as AnnotateResultRequest, //TODO THIS NEEDS TO BE A MIX OF BOTH; OR INHERITING INTERFACES
    result: {},
    status: 0,
  };
  searchResult.errorMessage = '';
  const idToken = yield select((state: AppState) => state.auth.idToken);
  const postData: AnnotateQueryParameter = {
    dataset_id: action.payload.dataset_id,
    cutoff_type: action.payload.cutoff_type,
  };

  try {
    // Call the Annotate API endpoint
    const searchResult: SearchResult = yield call(
      fetchAnnotateResults,
      idToken,
      postData,
    );

    // enforce to bind datasets
    let availableDatasets = yield select((state: AppState) => state.dataSets);

    // ENFORCE to wait until datasets are loaded if loads of data for slow API. But dont max this out.
    if (availableDatasets.dataSets.length === 0) {
      yield put(dataSetsLoad());
      let i: number = 0;
      while (i < 20 && availableDatasets.dataSets.length === 0) {
        i += 1;
        yield delay(500); // 500ms delay to avoid busy waiting
        availableDatasets = yield select((state: AppState) => state.dataSets);
      }
    }

    yield put(annotateLoadSuccess(searchResult));
  } catch (e) {
    yield put(annotateLoadError(e.message || e.errorMessage || 'Unknown error'));
  }
}

function* watchAnnotateLoad() {
  yield takeEvery('GET_ANNOTATE_RESULT', handleAnnotateLoad);
}

export const annotateSagas = [watchAnnotateLoad()];
