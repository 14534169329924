import { themeGet } from '@styled-system/theme-get';
import styled, { keyframes, css } from 'styled-components';
import {
  compose,
  gridArea,
  GridAreaProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system';

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate(0, 1rem);
  }

  100% {
    opacity: 1;
    transform: none;
  }
`;

const fadeInAnimationCSS = css`
  animation: ${fadeInAnimation} 300ms ease-out;
`;

type RoundedBordersType = 'all' | 'none' | 'top' | 'bottom' | 'right' | 'left';

interface BorderRadiusGetterArgs {
  roundedBorders?: RoundedBordersType;
  theme: any;
}

// const getBorderRadius = (args: BorderRadiusGetterArgs): string => {
//   const {
//     roundedBorders = 'all',
//     theme: {
//       radii: { default: radius = 0 },
//     },
//   } = args;
//   const b = (
//     tags: RoundedBordersType[],
//     roundedBorders: RoundedBordersType,
//     radius?: number,
//   ) => ([...tags, 'all'].includes(roundedBorders) ? radius : 0);
//   return [
//     ['top', 'left'],
//     ['top', 'right'],
//     ['bottom', 'right'],
//     ['bottom', 'left'],
//   ]
//     .map((tags) =>
//       b(tags as RoundedBordersType[], roundedBorders as RoundedBordersType, radius),
//     )
//     .join(' ');
// };

interface CardProps extends LayoutProps, SpaceProps, GridAreaProps {
  roundedBorders?: RoundedBordersType;
  fadeIn?: boolean;
  hideOverflow?: boolean;
  padding?: number;
}

export const Card = styled.div<CardProps>`
  background: #fff;
  border-right: 1px solid ${themeGet('colors.grey90')};
  padding: ${(props) => props.padding}px;
  max-width: 100%;
  box-sizing: border-box;
  ${({ fadeIn = true }) => fadeIn && fadeInAnimationCSS};
  transition: border-radius 250ms ease-out, transform 200ms ease-out;
  overflow: ${(props) => (props.hideOverflow ? 'hidden' : 'visible')};
  ${compose(layout, space, gridArea)};
`;

Card.defaultProps = {
  paddingBottom: 16,
};

export const DottedCard = styled(Card)`
  border: 2px dashed ${themeGet(`colors.grey20`)};
  border-radius: 8px;
  box-sizing: border-box;
  padding: ${themeGet('space.4')} ${themeGet('space.3')};
  margin-left: ${themeGet('space.4')};
  gap: 12px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  max-width: 900px;
`;

export const OutlinedCard = styled(Card)`
  border: 1px solid ${themeGet(`colors.grey80`)};
  border-radius: 8px;
  padding: ${themeGet('space.2')} ${themeGet('space.2')};
`;
