import {
  ComponentDetails,
  ExperimentDataResponse,
  ExperimentRaw,
} from '../components/CellMap';
import { ColumnTag } from '../components/Pages/Datasets/Upload/FileColumnSelector/_data';

export type DataListData = {
  total: number;
  dataPreview: DataPreview[];
  component_details: ComponentDetails;
};

export type DataPreview = {
  Gene_ID: string;
  experiments: Experiment[];
  noObservations: number;
  match_type_component: string;
  effect_size_classification: string;
};

export type Experiment = {
  id: string;
  name: string;
  match_type: string;
  data: { [key: string]: string };
};

const shapeDataList: (
  dataList: ExperimentDataResponse,
  experimentList: ExperimentRaw[],
) => DataListData = (dataList) => {
  const data: DataListData = {
    total: dataList.count,
    dataPreview: dataList.preview.map(
      ({ name: id, details, effect_size_classification }) => ({
        Gene_ID: id,
        experiments: details.map(({ name, match_type, data }) => {
          const columns: { [key: string]: string } = {};

          Object.keys(data).map((key: string) => {
            const index = parseInt(key, 10);

            return (columns[ColumnTag[index]] = data[index]);
          });

          return {
            id,
            name: name,
            match_type: match_type,
            data: columns,
          };
        }),
        noObservations: details.length,
        effect_size_classification: effect_size_classification,
        match_type_component: details
          .flatMap((match_type) => `${match_type.match_type}`)
          .join(),
      }),
    ),
    component_details: dataList.component_details,
  };

  // console.log(data);

  return data;
};

export default shapeDataList;
