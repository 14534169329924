import React from 'react';

export type ToggleValue = string | number;

export interface Option {
  value: string | number;
  icon?: React.ReactNode;
  label?: string;
  background?: string;
}

export interface ContainerProps {
  translationX: string;
  selectionWidth: string;
  selectionIndex: number;
  disabled?: boolean;
  design: ToggleStyle;
  background: string;
}

export interface OptionProps {
  selected: boolean;
  tabIndex?: number;
  design: ToggleStyle;
  disabled: boolean;
}

export enum ToggleStyle {
  Round = 'round',
  Tab = 'tab',
}

export interface MultiToggleHook {
  (options: Option[], value: string | number | null, gap: string): {
    selectionIndex: number;
    translationX: string;
    ref: React.RefObject<HTMLDivElement> | null;
    width: string;
    background: string;
  };
}
