import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const PageContent = styled.div`
  margin-top: var(--menu-bar-height);
  margin-bottom: calc(var(--footer-height));
  padding: 8px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > * {
    flex-grow: 1;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
