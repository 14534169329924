import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Link } from 'react-router-dom';

export const MenuLink = styled(Link)`
  margin: 4px 16px 0;
  padding: 4px 0;
  border-bottom: 2px solid transparent;
  background: transparent;
  display: inline-flex;
  place-items: center;
  color: ${themeGet('colors.text')};
  transition: background 250ms ease-out;
  text-decoration: none;
  font-weight: 600;

  sup {
    color: ${themeGet('colors.text')};
    font-style: italic;
    font-weight: 200;
    line-height: 32px;
    top: 32px;
    vertical-align: super;

    :hover {
      color: ${themeGet('colors.action')};
    }
  }

  &.stretch-left {
    margin-left: auto;
  }

  &.stretch-right {
    margin-right: auto;
  }

  &.active {
    border-bottom-color: ${themeGet('colors.text')};
  }

  :hover {
    color: ${themeGet('colors.action')};
    border-bottom: 2px solid ${themeGet('colors.text')};
  }

  > :nth-last-child(n + 1) {
    margin-right: 4px;
  }
`;
