import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import { TableRoot, Row, Cell, HeadCell } from '../layout';
import { DataPreview, Experiment } from '../../utils/shape-data-list';
import { Button } from '../Button';
import { toFormattedFloat } from '../../utils';

const Container = styled.div`
  padding: 0 ${themeGet('space.3')};
  overflow: auto;
`;

const Component = styled.div`
  float: left;

  box-sizing: border-box;
  width: calc(100% - 2 * ${themeGet('space.2')});
  margin: ${themeGet('space.4')} ${themeGet('space.2')} ${themeGet('space.3')}
    ${themeGet('space.3')};
  border-left: 1px solid ${themeGet('colors.grey20')};

  @media (min-width: 1920px) {
    width: calc(50% - 2 * ${themeGet('space.2')});
  }

  @media (min-width: 2560px) {
    width: calc(33.333% - 2 * ${themeGet('space.2')});
  }
`;

export const ExperimentContainer = styled.div`
  padding-left: ${themeGet('space.4')};
  padding-right: ${themeGet('space.4')};
  margin-top: ${themeGet('space.3')};
  margin-bottom: ${themeGet('space.4')};

  .queryHitHighlight {
    background-color: ${themeGet('colors.gold')};
    border-radius: ${themeGet('radii.large')};
    padding-left: ${themeGet('space.1')};
    padding-right: ${themeGet('space.1')};
  }
`;

export const ExperimentName = styled.div`
  font-size: 0.9rem;
  margin-left: ${themeGet('space.1')};
  margin-right: ${themeGet('space.1')};
  margin-bottom: ${themeGet('space.1')};
  width: fit-content;
`;

export const MetadataTagList = styled.div`
  font-size: 0.4rem;
  margin-left: ${themeGet('space.1')};
  margin-right: ${themeGet('space.1')};
  margin-bottom: ${themeGet('space.1')};
  width: fit-content;
`;

export const MetadataTag = styled.span`
  white-space: nowrap;
  font-size: ${themeGet('fontSizes.0')};
  margin: auto;
  margin-right: 8px;
  padding-right: 6px;
  padding-left: 6px;
  background: #e1fff3;
  border-radius: 100px;
  text-align: center;
`;

const ComponentTitle = styled.div`
  padding-left: ${themeGet('space.2')};
  padding-right: ${themeGet('space.4')};
  padding-bottom: ${themeGet('space.3')};
`;

export const Gray = styled.span`
  color: ${themeGet('colors.grey40')};
  font-size: 0.7rem;
`;

const PreviewEnd = styled.div`
  float: left;
  width: 100%;
`;

export const ComponentSize = styled.div<{ compSize: number; compOffset: number }>`
  width: ${({ compSize }) => (compSize ? compSize : 1)}px;
  height: ${({ compSize }) => (compSize ? compSize : 1)}px;
  margin-left: ${({ compOffset }) => (compOffset ? compOffset : 1)}px;
  margin-top: ${({ compOffset }) => (compOffset ? compOffset : 1)}px;
  text-align: center;
  line-height: ${({ compSize }) => (compSize ? compSize : 1)}px;
  font-weight: 700;
  background: ${themeGet('colors.grey20')};
  border: 1px solid ${themeGet('colors.grey20')};
  border-radius: 100%;
`;

type DataViewTableProps = {
  data: DataPreview[];
};

const DataViewTable = ({ data }: DataViewTableProps) => {
  const previewLimitDefault = 20;
  const previewLimitIncrement = 20;
  const [previewLimit, setPreviewLimit] = useState(previewLimitDefault);

  const effectSizeFilterDefault = 'NA';
  const [effectSizeFilter, setEffectSizeFilter] = useState(effectSizeFilterDefault);

  const renderExperiments = (
    index: number,
    experiments: Experiment[],
    noObservations: number,
    match_type_component: string,
    effect_size_classification: string,
  ) => {
    const experimentNames = experiments
      .flatMap((experiment) => experiment.name)
      .filter((name, index, array) => array.indexOf(name) === index);

    const compSize = 15 + Math.min(noObservations * 0.3, 40);

    return (
      <Component key={index}>
        <ComponentSize compSize={compSize} compOffset={-1 * Math.round(compSize / 2)}>
          {noObservations}
        </ComponentSize>
        <ComponentTitle>
          <Gray>"{experiments[0].id}"</Gray>
          <br />
          The
          {match_type_component.includes('Proxy') ? ' Cellmap ' : ' '}
          component consists of
          {noObservations === 1
            ? ` a single observation.`
            : ` ${noObservations} observations.`}
          {effect_size_classification !== 'NA' ? (
            <>
              <br />
              Regulation Effect:{' '}
            </>
          ) : (
            ''
          )}
          {effect_size_classification === 'DOWN' ? 'down' : ''}
          {effect_size_classification === 'UP' ? 'up' : ''}
          {effect_size_classification === 'MIXED' ? 'mixed' : ''}
        </ComponentTitle>
        {experimentNames.map((name) => {
          const experimentsWithSameName = experiments.filter(
            (experiment) => experiment.name === name,
          );
          const id = experiments[0].id;
          const noObsperExperiment = experimentsWithSameName.length;

          return (
            <ExperimentContainer key={`experiments-${name}-${id}`}>
              <ExperimentName>{name}</ExperimentName>
              <TableRoot smallFont>
                {experimentsWithSameName
                  .map(({ data, id, name }, index) => (
                    <Fragment key={`${id}-${index}`}>
                      {!index && (
                        <Row key={`head-${id}`}>
                          {Object.keys(data).map((key) => (
                            <HeadCell key={`head-${id}-${key}`}>{key}</HeadCell>
                          ))}
                        </Row>
                      )}
                      <Row key={`data-${id}-${index}`}>
                        {Object.keys(data).map((key) => (
                          <Cell key={`data-${id}-${index}-${key}`}>
                            {key === 'Adjusted significance' ||
                            key === 'Significance' ||
                            key === 'Effect size measure'
                              ? toFormattedFloat(data[key])
                              : data[key]}
                          </Cell>
                        ))}
                      </Row>
                    </Fragment>
                  ))
                  .slice(0, Math.min(8, noObsperExperiment))}
              </TableRoot>
              <small>
                {noObsperExperiment > 7
                  ? '… and more observations with lower significance.'
                  : ''}
              </small>
            </ExperimentContainer>
          );
        })}
      </Component>
    );
  };

  const renderDataTable = (data: DataPreview[]) => {
    if (!data || !data.length) return null;

    let filteredData = data
      .filter(
        (d) =>
          effectSizeFilter === 'NA' || d.effect_size_classification === effectSizeFilter,
      )
      .map((dataPreview, index) =>
        renderExperiments(
          index,
          dataPreview.experiments,
          dataPreview.noObservations,
          dataPreview.match_type_component,
          dataPreview.effect_size_classification,
        ),
      )
      .slice(0, Math.min(data.length, previewLimit));

    const number_down_regulated_components = data.filter(
      (d) => d.effect_size_classification === 'DOWN',
    ).length;
    const number_up_regulated_components = data.filter(
      (d) => d.effect_size_classification === 'UP',
    ).length;
    const number_mixed_regulated_components = data.filter(
      (d) => d.effect_size_classification === 'MIXED',
    ).length;

    const show_regulation_filter: boolean =
      number_down_regulated_components > 0 ||
      number_up_regulated_components > 0 ||
      number_mixed_regulated_components > 0;

    return (
      <Container>
        {show_regulation_filter === true ? (
          <>
            Filter preview by regulation: <br />
            &nbsp;
            <Button onClick={() => setEffectSizeFilter('UP')}>
              {number_up_regulated_components} Upregulated
            </Button>
            <Button onClick={() => setEffectSizeFilter('DOWN')}>
              {number_down_regulated_components} Downregulated
            </Button>
            <Button onClick={() => setEffectSizeFilter('MIXED')}>
              {number_mixed_regulated_components} Mixed regulation
            </Button>
            <Button onClick={() => setEffectSizeFilter('NA')}>All</Button>
          </>
        ) : (
          ''
        )}
        {filteredData}
        <PreviewEnd>
          {previewLimit < data.length ? (
            <Button onClick={() => setPreviewLimit(previewLimit + previewLimitIncrement)}>
              Load more
            </Button>
          ) : (
            ''
          )}
          {previewLimit !== previewLimitDefault ? (
            <Button onClick={() => setPreviewLimit(previewLimitDefault)}>
              Collapse preview
            </Button>
          ) : (
            ''
          )}
        </PreviewEnd>
      </Container>
    );
  };

  return <>{renderDataTable(data)}</>;
};

export default DataViewTable;
