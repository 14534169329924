import styled, { css } from 'styled-components';
import { compose, gridArea, GridAreaProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

export const TextArea = styled.textarea<LayoutProps & GridAreaProps & SpaceProps & { hideInvalid?: boolean }>`
  padding: .5rem .25rem;
  font-size: 1rem;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${themeGet('colors.grey70')};
  border-radius: ${themeGet('radii.small')};
  resize: vertical;
  ${compose(layout, space, gridArea)};

  :focus {
    outline: 0;
    border-color: ${themeGet('colors.grey10')};
  }
  
  &[readonly] {
    background: ${themeGet('colors.grey90')};
    color: ${themeGet('colors.grey30')};
    border-color: ${themeGet('colors.grey30')};
  }
  
  :invalid {
    ${props => !props.hideInvalid && css`
      border-color:  ${themeGet('colors.error')};
      box-shadow: none;
    `}
  }
`;

TextArea.displayName = 'TextArea';
