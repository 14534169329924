import { ObjectOrArray, Theme } from 'styled-system';
import chroma from 'chroma-js';
import * as CSS from 'csstype';

const createPalette = (baseColor: string, name: string): Colors => ({
  [name]: baseColor,
  [`${name}-dark-2`]: chroma(baseColor).darken(1.5).hex(),
  [`${name}-dark-1`]: chroma(baseColor).darken(0.5).hex(),
  [`${name}-light-1`]: chroma(baseColor).brighten(0.5).hex(),
  [`${name}-light-2`]: chroma(baseColor).brighten(1.5).hex(),
});

const gold = createPalette('#ffcb52', 'gold');
const goldDark = createPalette('#eba400', 'gold-dark');
const sky = createPalette('#237bc0', 'sky');
const tomato = createPalette('#d83e38', 'tomato');
const midnight = createPalette('#2c3e50', 'midnight');
const eggplant = createPalette('#350f50', 'eggplant');
const grass = createPalette('#2dab2c', 'grass');
const grey = {
  grey10: '#1a1a1a',
  grey20: '#333333',
  grey30: '#4d4d4d',
  grey40: '#666666',
  grey50: '#808080',
  grey60: '#999999',
  grey70: '#b3b3b3',
  grey80: '#cccccc',
  grey90: '#e6e6e6',
  grey99: '#333333',
};

interface Colors {
  [colorName: string]: string;
}

const colors: Colors = {
  ...grey,
  ...gold,
  ...goldDark,
  ...sky,
  ...tomato,
  ...midnight,
  ...eggplant,
  ...grass,
  focus: '#385DC4',
  action: '#eba400',
  actionHover: '#eba400',
  highlight: '#efdf5e',
  background: '#fff',
  cardBack: '#fff',
  rowBack: '#fff',
  rowBackAlt: '#fafafa', // color used for alternating rows
  rowBackHover: '#cdebf5',
  success: '#00bf00',
  error: '#faddd7',
  tableSeparator: '#000',
  neutralContrastA: '#ff8a42', // used for distinctions without assessment/judgement; e.g. two values are distinct, but neither is inherently better or worse
  neutralContrastB: '#0c7dce',

  inputBorder: '#979797',
  text: '#323232',
};

const radii: ObjectOrArray<CSS.ColorProperty> = ['5px', '10px', '25px'];
// @ts-ignore
radii.small = radii[0];
// @ts-ignore
radii.default = radii[1];
// @ts-ignore
radii.large = radii[2];

// enforce a format, `rgb(r,g,b)`, which is reliably usable everywhere
// hex can be a problem within SVGs under certain conditions
const formatColors = (colors: Colors) =>
  Object.keys(colors).reduce(
    (acc, key) => ({ ...acc, [key]: chroma(colors[key]).css() }),
    colors,
  );

export const standard: Theme = {
  // @ts-ignore
  colors: formatColors(colors),
  fonts: {
    default: 'Nunito, Frutiger, Helvetica, Arial, sans-serif',
    mono: 'Monaco, monospace',
  },
  fontSizes: ['12px', '14px', '16px', '20px'],
  radii,
  borders: ['1px solid #979797'],
  space: [0, '4px', '8px', '16px', '24px', '32px'],
  shadows: {
    focus: `0 0 10px ${colors['sky-dark-2']}99`,
    danger: `0 0 10px ${colors['tomato-dark-2']}99`,
  },
};
