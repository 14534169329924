export * from './App';
export { default } from './App';

declare global {
  interface Window {
    env: any;
  }
}

// See /public/index.html for definition
window.env = window.env || {};
