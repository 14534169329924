import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const UnsortedList = styled.ul`
  margin: 0;
  padding: 0 0 0 0.5rem;
  list-style-type: none;
  font-size: ${themeGet('fontSizes.0')};
`;

export const ListItem = styled.li`
  position: relative;
  text-align: left;

  &::before {
    content: '•';
    position: absolute;
    left: -0.5rem;
    height: 0;
  }

  &:nth-child(n + 2) {
    margin-top: 0.5rem;
  }
`;
