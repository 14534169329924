import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { uploadFile } from '../../../../store/upload';
import { AppState } from '../../../../store';
import { SubmitHandler } from 'react-hook-form';
import { UploadFormValues } from './Upload';

export const useUpload = () => {
  const [file, setFile] = React.useState<File | undefined>();
  const handleFiles = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const file: File | undefined = event.currentTarget?.files?.[0];
    setFile(file);
  };
  return {
    handleFiles,
    file,
    setFile,
  };
};

export const useFormSubmit = (file?: File) => {
  const [hideInvalid, setHideInvalid] = React.useState<boolean>(true);
  const dispatch = useDispatch();
  const [disabled, setDisabled] = React.useState<boolean>();

  const { push } = useHistory();
  const onSubmit: SubmitHandler<UploadFormValues> = (
    data: UploadFormValues,
    event?: React.BaseSyntheticEvent<object, any, any>,
  ) => {
    event?.preventDefault();
    setHideInvalid(false);
    const valid = !!event?.target?.checkValidity();

    if (!valid || !file) {
      return;
    }

    setDisabled(true);
    dispatch(uploadFile(file as File, data, push));
    setHideInvalid(true);
  };

  return {
    hideInvalid,
    onSubmit,
    disabled,
    setDisabled,
  };
};

export const useUploadLimits = () => {
  const length = useSelector((state: AppState) => state.dataSets?.dataSets?.length);
  const fileLimit = useSelector(
    (state: AppState) => state.user.customer?.credits_total || undefined,
  );
  const limitsUnknown = length === undefined || fileLimit === undefined;
  const atUploadLimit = length === fileLimit;
  return {
    limitsUnknown,
    atUploadLimit,
  };
};
