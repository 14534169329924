import styled from 'styled-components';
import { gridArea, space, GridAreaProps, SpaceProps } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

interface LabelProps
  extends React.LabelHTMLAttributes<HTMLLabelElement>,
    GridAreaProps,
    SpaceProps {}

export const Label = styled.label<LabelProps>`
  font-size: 1rem;
  display: block;
  margin: ${themeGet('space.2')} 0 ${themeGet('space.1')};
  line-height: 1.5;
  ${gridArea};
  ${space};

  :first-child {
    margin-top: 0;
  }
`;
