import * as React from 'react';
import * as T from './_types';

const createObservablePromise = <R = any, E extends Error = Error>(
  createPromise: () => Promise<R>,
  handleEvent: (action: T.PromiseAction<E>) => void,
) => {
  let unsubscribed = false;
  const publish = (action: T.PromiseAction<E>) =>
    !unsubscribed && handleEvent(action);
  const run = async () => {
    publish({ type: 'start' });
    try {
      const payload = await createPromise();
      publish({ type: 'success', payload });
    } catch (error) {
      publish({ type: 'error', payload: error as E });
    }
  };
  const unsubscribe = () => void (unsubscribed = true);
  return {
    unsubscribe,
    run,
  };
};

const reducer: T.PromiseReducer = <R, E extends Error>(
  state: T.AsyncStatus<R, E>,
  action: T.PromiseAction<E>,
) => {
  switch (action.type) {
    case 'start':
      return {
        ...state,
        done: false,
        loading: true,
      };
    case 'error':
      return {
        loading: false,
        error: action.payload,
        data: undefined,
        done: false,
      };
    case 'success':
      return {
        loading: false,
        error: undefined,
        data: action.payload || true,
        done: true,
      };
    default:
      return state;
  }
};

export const usePromise: T.PromiseHook = <R, E extends Error>(
  createPromise: () => Promise<R>,
) => {
  const [state, dispatch] = React.useReducer<T.PromiseReducer<R, E>>(
    (reducer as unknown) as T.PromiseReducer<R, E>,
    {
      loading: false,
      done: false,
    },
  );
  const { run, unsubscribe } = React.useMemo(() => {
    const handleEvent = (action: T.PromiseAction) => dispatch(action);
    return createObservablePromise(createPromise, handleEvent);
  }, [createPromise, dispatch]);

  React.useEffect(() => () => unsubscribe(), [unsubscribe]);

  return [run, state];
};
