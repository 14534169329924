import React from 'react';
import * as S from './_style';

const LegalDisclosure: React.FC = () => {
  return (
    <S.Container>
      <S.Title>Legal Disclosure</S.Title>

      <S.Section>
        <S.Paragraph>Disclosures in accordance with § 5 TMG</S.Paragraph>
      </S.Section>

      <S.Section>
        <S.Address>
          knowing<sup>01</sup> GmbH
          <br />
          Am Stadtpark 66
          <br />
          D-81243 München
        </S.Address>
      </S.Section>

      <S.Section>
        <S.Paragraph>
          <a href="mailto:support@knowing01.com">support@knowing01.com</a>
          <a href="tel:00498974678081" rel="nofollow">
            {' '}
            Phone +49 89 74678081
          </a>
        </S.Paragraph>
      </S.Section>

      <S.Section>
        <S.Paragraph>Registry court: Amtsgericht München</S.Paragraph>
        <S.Paragraph>Register number: HRB 255321</S.Paragraph>
        <S.Paragraph>
          Authorised representative/Managing director: Dr. Nikola Müller
        </S.Paragraph>
      </S.Section>
    </S.Container>
  );
};

export default LegalDisclosure;
