import * as React from 'react';

import { UploadParametersInput } from './UploadParametersInput';
import { Biotype, GenomeAssembly, Taxonomy } from '../_types';
import { useForm, useWatch } from 'react-hook-form';
import { UploadFormValues } from '../Upload';
import { Headline } from '../../../../text/Headline';

interface UploadParametersProps {
  file?: File;
  hideInvalid?: boolean;
}

export const UploadParameters: React.FC<UploadParametersProps> = ({
  file,
  hideInvalid,
}) => {
  const form = useForm<UploadFormValues>();
  const biotype: string = useWatch({
    name: 'biotype',
    defaultValue: form.getValues('biotype'),
  });
  const showGenomeAssemblyInput =
    parseInt(biotype, 10) === Biotype['Genomic Range'] ||
    parseInt(biotype, 10) === Biotype.Variant;
  const showTaxonomyInput = parseInt(biotype, 10) === Biotype['Gene Symbol'];

  return (
    <>
      <br />
      <UploadParametersInput
        id={'name'}
        fieldType={'text'}
        label={'Short name'}
        tooltip={'Short name of dataset'}
        hideInvalid={hideInvalid}
        maxLength={100}
      />
      <small>
        Use a crisp and concise name that is easy to understand for others, too (max. 100
        characters).
      </small>
      <p></p>

      <UploadParametersInput
        id={'description'}
        fieldType={'textarea'}
        label={'Description'}
        tooltip={'Long description'}
        hideInvalid={hideInvalid}
      />
      <small>
        Record additional information that can be helpful to interpret results later, such
        as experimental protocols, statistical methods used, number of samples etc.
      </small>
      <p></p>

      <UploadParametersInput
        id={'metadata_tags'}
        fieldType={'text_optional'}
        label={'Tags'}
        tooltip={'Add tags, separator=;'}
        hideInvalid={hideInvalid}
      />
      <small>Add some tags to your data, separated by ";".</small>
      <p></p>

      <UploadParametersInput
        id={'filetypeInfo'}
        fieldType={'paragraph'}
        label={''}
        tooltip={'Detailed file type description'}
        hideInvalid={hideInvalid}
      />
      <p></p>

      <Headline design={'h2'} paddingLeft={0}>
        2. Select matching biotype
      </Headline>

      <UploadParametersInput
        id={'biotype'}
        fieldType={'select'}
        label={'Biotype: '}
        tooltip={'Select biotype'}
        options={[
          { value: Biotype.Gene, label: Biotype[Biotype.Gene] },
          { value: Biotype['Gene Symbol'], label: Biotype[Biotype['Gene Symbol']] },
          { value: Biotype.Variant, label: Biotype[Biotype.Variant] },
          { value: Biotype['Genomic Range'], label: Biotype[Biotype['Genomic Range']] },
          { value: Biotype.Transcript, label: Biotype[Biotype.Transcript] },
          { value: Biotype.Protein, label: Biotype[Biotype.Protein] },
        ]}
        defaultValue={Biotype.Gene}
        hideInvalid={hideInvalid}
      />
      {showGenomeAssemblyInput && (
        <UploadParametersInput
          id={'genomeAssembly'}
          fieldType={'select'}
          label={'Genome Assembly: '}
          options={[
            {
              value: GenomeAssembly['GRCh37/hg19'],
              label: GenomeAssembly[GenomeAssembly['GRCh37/hg19']],
            },
            {
              value: GenomeAssembly.GRCh38,
              label: GenomeAssembly[GenomeAssembly.GRCh38],
            },
            {
              value: GenomeAssembly.GRChm38,
              label: GenomeAssembly[GenomeAssembly.GRChm38],
            },
          ]}
          defaultValue={GenomeAssembly['GRCh37/hg19']}
          hideInvalid={hideInvalid}
        />
      )}
      {showTaxonomyInput && (
        <UploadParametersInput
          id={'taxonomy'}
          fieldType={'select'}
          label={'Organism: '}
          options={[
            {
              value: Taxonomy['human (taxID:9606)'],
              label: Taxonomy[Taxonomy['human (taxID:9606)']],
            },
            {
              value: Taxonomy['mouse (taxID:10096)'],
              label: Taxonomy[Taxonomy['mouse (taxID:10096)']],
            },
            {
              value: Taxonomy['rat (taxID:10116)'],
              label: Taxonomy[Taxonomy['rat (taxID:10116)']],
            },
          ]}
          defaultValue={Taxonomy['human (taxID:9606)']}
          hideInvalid={hideInvalid}
        />
      )}
      <small>
        Choose the biotype of the dataset. Please keep in mind:
        <ul>
          <li>
            <small>
              To prefer Gene (e.g. ENSEMBL identifier) over GeneSymbol (e.g. Cdc42,
              CXCL-8). GeneSymbols are easy to read but are not always unique when it
              comes to mapping data.
            </small>
          </li>
          <li>
            <small>
              for Variant or Genomic Range to also specify the genome assembly version
              which was used to generate your data. This is crucial to match correctly
              between genomic positions and directly impacts results.
            </small>
          </li>
        </ul>
      </small>

      <Headline design={'h2'} paddingLeft={0}>
        3. Tag columns of your file
      </Headline>
      <UploadParametersInput
        id={'columnSelect'}
        fieldType={'columnSelect'}
        label={''}
        file={file}
        hideInvalid={hideInvalid}
      />
      <small>
        Tag your data to allow us to read the file correctly. Short guidance and read our
        Info page for{' '}
        <a href="/method-description/#search" target="_blank">
          more details on column tags
        </a>
        .
        <ul>
          <li>
            <small>
              Link identifier is required to compare your data via the cell map for the
              biotypes:
            </small>
          </li>
          <ul>
            <li>
              <small>
                <strong>Gene</strong>, e.g. "ENSMUSG00000002111"
              </small>
            </li>
            <li>
              <small>
                <strong>GeneSymbol</strong>, e.g. "Spi1"
              </small>
            </li>
            <li>
              <small>
                <strong>Transcript</strong>, e.g. "ENSMUST00000169852.1"
              </small>
            </li>
            <li>
              <small>
                <strong>Protein</strong>, e.g. "P17433"
              </small>
            </li>
          </ul>
          <li>
            <small>
              Chromosome/Start(/Stop) is required to compare your data via the cell map
              for the biotypes:
            </small>
          </li>
          <ul>
            <li>
              <small>
                <strong>Variant</strong>
              </small>
            </li>
            <li>
              <small>
                <strong>Genomic Ranges</strong>
              </small>
            </li>
          </ul>
          <li>
            <small>
              <strong>(Adjusted) Significance</strong> tags are strongly recommended. Tag
              Significane (e.g. p-value) and Adjusted Significance tags (e.g. q-value,
              FDR, adj. p-value) columns. These are used to split your data into
              regulated/non-regulated set of data entries. Sometimes a{' '}
              <strong>Score</strong> is available instead of a signifcance, which assumes
              all positive values and high values are considered important.
            </small>
          </li>
          <li>
            <small>
              Use the <strong>Cutom tags</strong> to tag more columns of the file, which
              shall be displayed in the explore result view.
            </small>
          </li>
          <li>
            <small>
              <strong>Data Slice</strong> Usage: If your upload includes multiple types of
              comparisons in a long format, such as marker genes per cell type or pathway
              names in a gene-to-pathway master table, utilize the dataslice tag to
              categorize these columns appropriately. This helps in managing and
              navigating through categorical data efficiently.
            </small>
          </li>
          <li>
            <small>
              The more columns are tagged, the better the data can be analyzed. For
              example Effect size measure: Foldchange/Effect size can be used in upcoming
              releases.
            </small>
            <small>
              Single Cell Datasets: For datasets containing single cell data, the PC1 and
              PC2 columns, when available, denote the fraction of cells where the feature
              is observed within and outside of the cluster, respectively.
            </small>
          </li>
        </ul>
      </small>
    </>
  );
};

UploadParameters.displayName = 'UploadParameters';
