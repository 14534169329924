import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { AppState } from '../../store';

export const useLeaveWarning = () => {
  const hasUploads: boolean = useSelector(
    (state: AppState) => Object.entries(state.upload).length > 0,
  );

  const handleUnload = React.useCallback(
    (event: WindowEventMap['beforeunload']) => {
      if (!hasUploads) return;
      event.returnValue =
        'You are currently uploading files. Leaving the page would abort those downloads!';
    },
    [hasUploads],
  );

  React.useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);
    return () => window.removeEventListener('beforeunload', handleUnload);
  }, [handleUnload]);

  const completeProfile = useSelector((state: AppState) => state.auth.completeProfile);
  const { push } = useHistory();
  React.useEffect(() => {
    if (completeProfile === false) push('/finalize-registration');
  }, [completeProfile, push]);
};
