import * as React from 'react';

import { TextArea } from '../../../../inputs/TextArea';
import { Checkbox, Input, Label, NativeSelect } from '../../../../inputs';
import { Box } from '../../../../layout/Box';
import { useFormContext } from 'react-hook-form';
import { FileColumnSelector } from '../FileColumnSelector/FileColumnSelector';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { UploadParameterInput } from './_types';

const FieldParagraph = styled.p`
  margin-top: 0;
  margin-bottom: ${themeGet('space.3')};
  padding-right: 0;
  padding-left: 0;
`;

export const UploadParametersInput: React.FC<UploadParameterInput> = (props) => {
  const { register } = useFormContext();

  switch (props.fieldType) {
    case 'text': {
      return (
        <div key={props.id}>
          <Label>
            {props.label}
            <Input
              type="text"
              name={props.id}
              hideInvalid={props.hideInvalid}
              required
              ref={register}
              maxLength={props.maxLength}
            />
          </Label>
        </div>
      );
    }

    case 'text_optional': {
      return (
        <div key={props.id}>
          <Label>
            {props.label}
            <Input
              type="text"
              name={props.id}
              hideInvalid={props.hideInvalid}
              ref={register}
            />
          </Label>
        </div>
      );
    }

    case 'textarea': {
      return (
        <div key={props.id}>
          <Label>
            {props.label}
            <TextArea
              name={props.id}
              width={1}
              height={100}
              hideInvalid={props.hideInvalid}
              required
              ref={register}
            />
          </Label>
        </div>
      );
    }

    case 'number': {
      return (
        <div key={props.id}>
          <Label>
            {props.label}
            <Input
              type="number"
              name={props.id}
              min={props.min}
              max={props.max}
              step={props.step}
              defaultValue={props.defaultValue}
              hideInvalid={props.hideInvalid}
              required
              ref={register}
            />
          </Label>
        </div>
      );
    }

    case 'select':
      return (
        <div key={props.id}>
          <Label>
            {props.label}

            <NativeSelect
              name={props.id}
              defaultValue={props.defaultValue}
              hideInvalid={props.hideInvalid}
              required
              ref={register}
            >
              {props.options.map(({ value, label }) => (
                <option value={value} key={value}>
                  {label}
                </option>
              ))}
            </NativeSelect>
          </Label>
        </div>
      );

    case 'checkbox':
      return (
        <Box marginTop={3} marginBottom={2} key={props.id}>
          <Label>
            <Checkbox
              name={props.id}
              defaultChecked={props.defaultValue}
              verticalAlign="middle"
              marginRight={1}
              style={{ marginRight: 8 }}
              ref={register}
            />
            {props.label}
          </Label>
        </Box>
      );

    case 'columnSelect':
      if (!props.file) return null;

      return (
        <Box marginTop={3} marginBottom={2} key={props.id}>
          <Label>{props.label}</Label>
          <FileColumnSelector
            file={props.file}
            namePrefix={props.id + '.'}
            lineCount={3}
          />
        </Box>
      );

    case 'paragraph': {
      return (
        <FieldParagraph key={props.id}>
          <small>{props.label}</small>
        </FieldParagraph>
      );
    }

    default:
      return null;
  }
};

UploadParametersInput.displayName = 'UploadParametersInput';
