import React from 'react';

import * as S from './_style';

export interface LinkProps {
  style?: React.CSSProperties;
  className?: string;
  to: string;
  component?: React.ComponentType<any>;
  replace?: boolean;
  innerRef?: React.Ref<HTMLAnchorElement>;
  target?: '_self' | '_blank' | '_parent' | '_top' | string;
  rel?: string;
  onClick?: any;
}

export const Link: React.FC<LinkProps> = ({
  style,
  className,
  to,
  children,
  component,
  replace,
  innerRef,
  target,
  rel,
  onClick,
}) => (
  <S.Link
    style={style}
    className={className}
    to={to}
    component={component}
    replace={replace}
    innerRef={innerRef}
    target={target}
    rel={rel}
    onClick={onClick}
  >
    {children}
  </S.Link>
);
