import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { path } from 'ramda';

import * as S from './_style';
import * as T from './_types';
import * as E from './_effects';
import * as D from './_data';

import { ErrorMessage } from '../../../../text';
import { Box } from '../../../../layout/Box';
import { NativeSelect } from '../../../../inputs/NativeSelect';
import { ColumnOption } from './_data';
import { FilePreview } from '../../FilePreview';

const getMissingValues = (allValues: string[], formBiotype: string): string[] => {
  const mandatoryValues = D.columnOptions.filter(
    (option) =>
      option.mandatory &&
      (isOptionBiotypeSet(option, formBiotype)
        ? isOptionBiotypeSelected(option, formBiotype)
        : true),
  );

  return mandatoryValues
    .filter((value, index) => !allValues.includes(value.id.toString()))
    .map((value) => value.description);
};

const isOptionBiotypeSet = (option: ColumnOption, formBiotype: string) =>
  !!formBiotype && !!option.biotype;

const isOptionBiotypeSelected = (option: ColumnOption, formBiotype: string) =>
  isOptionBiotypeSet(option, formBiotype) && option.biotype === parseInt(formBiotype, 10);

export const FileColumnSelector: React.FC<T.FileColumnSelectorProps> = ({
  style,
  className,
  file,
  namePrefix = '',
  lineCount = 2,
  ...styleProps
}) => {
  const {
    register,
    getValues,
    errors,
    watch,
    trigger,
    formState: { submitCount },
  } = useFormContext();
  const { error, lines } = E.useLinesFromFile(file, lineCount);
  const data = E.useCSV(lines).data as string[][];
  const columns = (data || []).reduce<number>(
    (acc: number, columns: string[]) => Math.max(columns.length, acc),
    0,
  );
  const names: string[] = React.useMemo(
    () =>
      Array(columns)
        .fill(null)
        .map((_, index) => `${namePrefix}${index}`),
    [columns, namePrefix],
  );
  const watchFields = watch(names);

  const form = useFormContext();
  const formBiotype: string = useWatch({
    name: 'biotype',
    defaultValue: form.getValues('biotype'),
  });
  const missingValues = getMissingValues(Object.values(getValues(names)), formBiotype);

  React.useEffect(() => {
    if (submitCount === 0) return;

    const triggerNames = Object.entries(watchFields)
      .filter(([key, value], index, entries) => {
        const hasDuplicates =
          entries.filter(([_, entryValue]) => entryValue === value && !!value).length > 1;
        const hasError =
          path([...key.split('.'), 'message'], errors) === D.errorMessages.duplicate;
        return (!hasDuplicates && hasError) || (hasDuplicates && !hasError);
      })
      .map((entry) => entry[0]);

    if (triggerNames.length === 0) return;

    trigger(triggerNames);
  }, [watchFields, errors, trigger, submitCount]);

  if (error) {
    return (
      <ErrorMessage style={style} className={className}>
        {error.message}
      </ErrorMessage>
    );
  }

  return (
    <S.Container style={style} className={className} {...styleProps}>
      {!!missingValues.length && submitCount > 0 && (
        <ErrorMessage marginBottom={2}>
          {D.errorMessages.missingColumn} {missingValues.join(', ')}
        </ErrorMessage>
      )}
      <Box display="grid" gridTemplateColumns={`repeat(${columns}, 1fr)`} gridGap={1}>
        {names.map((name) => {
          // @ts-ignore
          const errorMessage = path(name.split('.'), errors)?.['message'];

          return (
            <Box key={name} width={1}>
              <NativeSelect
                style={{ width: '100%' }}
                width="100%"
                name={name}
                defaultValue=""
                ref={register({
                  validate: async (value: any) => {
                    const allValues = Object.values(getValues(names));

                    // test if this value has been selected multiple times
                    const hasDuplicateEntries =
                      allValues.filter((val) => value === val).length > 1;

                    if (hasDuplicateEntries && !!value) {
                      return D.errorMessages.duplicate;
                    }

                    // test if required fields are missing
                    const missingValues = getMissingValues(allValues, formBiotype);

                    if (missingValues.length) {
                      return '';
                    }

                    return true;
                  },
                })}
              >
                <option value="">-</option>
                {D.columnOptions.map((option) => {
                  if (
                    isOptionBiotypeSet(option, formBiotype) &&
                    !isOptionBiotypeSelected(option, formBiotype)
                  ) {
                    return null;
                  }

                  return (
                    <option value={option.id} key={option.id}>
                      {option.description}
                    </option>
                  );
                })}
              </NativeSelect>

              {errorMessage && <ErrorMessage marginTop={1}>{errorMessage}</ErrorMessage>}
            </Box>
          );
        })}

        <FilePreview data={data} />
      </Box>
    </S.Container>
  );
};

FileColumnSelector.displayName = 'FileColumnSelector';
