import * as React from 'react';

import * as S from './_style';
import * as T from './_types';

export const Checkbox = React.forwardRef<HTMLInputElement, T.CheckboxProps>(
  (
    {
      style,
      className,
      type,
      margin,
      marginTop,
      mt,
      marginRight,
      m,
      marginBottom,
      mb,
      marginLeft,
      ml,
      marginX,
      mx,
      marginY,
      my,
      padding,
      p,
      paddingTop,
      pt,
      paddingRight,
      pr,
      paddingBottom,
      pb,
      paddingLeft,
      pl,
      paddingX,
      px,
      paddingY,
      py,
      width,
      height,
      display,
      minWidth,
      minHeight,
      maxWidth,
      maxHeight,
      size,
      verticalAlign,
      overflow,
      overflowX,
      overflowY,
      gridArea,
      disabled = false,
      ...htmlAttributes
    },
    ref,
  ) => {
    const styledSystemProps = {
      margin,
      marginTop,
      mt,
      marginRight,
      m,
      marginBottom,
      mb,
      marginLeft,
      ml,
      marginX,
      mx,
      marginY,
      my,
      padding,
      p,
      paddingTop,
      pt,
      paddingRight,
      pr,
      paddingBottom,
      pb,
      paddingLeft,
      pl,
      paddingX,
      px,
      paddingY,
      py,
      width,
      height,
      display,
      minWidth,
      minHeight,
      maxWidth,
      maxHeight,
      size,
      verticalAlign,
      overflow,
      overflowX,
      overflowY,
      gridArea,
    };
    return (
      <S.Container style={style} className={className} {...styledSystemProps}>
        <S.Checkbox ref={ref} type="checkbox" disabled={disabled} {...htmlAttributes} />
        <S.Checkmark disabled={disabled}/>
      </S.Container>
    );
  },
);

Checkbox.displayName = 'Checkbox';
