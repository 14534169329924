import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import { DatasetStatus } from '../DatasetStatus';
import { Card } from '../../../layout';
import { DatasetItemStatus, ImportFileError, ImportLineError } from '../_types';
import { AppState, dataSetsRemove } from '../../../../store';
import { DetailsUpload } from './DetailsUpload';
import { Button, ButtonDesign } from '../../../Button';
import { ErrorMessage, Headline, WarningMessage } from '../../../text';
import { Biotype, GenomeAssembly, Taxonomy } from '../Upload/_types';
import { columnOptions, ColumnTag } from '../Upload/FileColumnSelector/_data';
import { FilePreview } from '../FilePreview';
import { linebreak, mailto } from '../../../../services/mailto';
import { DatasetCutoff } from '../DatasetCutoff/DatasetCutoff';
import { PostProcessingType } from '../../../CellMap';
import { MetadataTag, MetadataTagList } from '../../../data-preview/DataViewTable';
import { UpdateCoreParameters } from '../Upload/UploadParameters/UpdateCoreParameters';

const Title = styled.div`
  display: flex;
  white-space: pre-wrap;

  > :last-child {
    margin-left: 0;
    margin-right: ${themeGet('space.4')};
  }
`;

const Props = styled.div`
  margin: 0 ${themeGet('space.4')} ${themeGet('space.4')};

  > * {
    margin-bottom: ${themeGet('space.2')};
  }
`;

export const DataSetProgress = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${themeGet('space.4')};

  p {
    margin-top: ${themeGet('space.2')};
    margin-bottom: ${themeGet('space.2')};
  }
`;

export const ProgressBar = styled.div<{ error: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform-origin: left;
  background: ${({ error }) =>
    error ? themeGet('colors.error') : themeGet('colors.grey90')};
  transition: transform 800ms linear;
  z-index: -1;
`;

export const ProgressContainer = styled.div`
  position: relative;
  background: white;
  overflow: hidden;
  height: 50px;
  border-radius: 25px;
  border: 1px dashed #323232;
  width: 200px;
  z-index: 0;

  p {
    font-weight: 700;
    margin: 13px;
    z-index: 2;
  }

  small {
    padding-top: 0;
  }
`;

export const QueuedStateContainer = styled.div<{ mark: boolean }>`
  position: relative;
  background: ${({ mark }) => (mark ? themeGet('colors.grey90') : 'white')};
  height: 35px;
  border-radius: 100%;
  width: 35px;

  :before {
    content: '...';
    font-size: 34px;
    font-weight: 1000;
    padding: 0;
    margin: 0 5px;
    color: #666;
    line-height: 0.5;
  }
`;

export const FullDescription = styled.div`
  white-space: pre-line;
`;

export interface DatasetDetailsProps {
  style?: React.CSSProperties;
  className?: string;
}

export const DatasetDetails: React.FC<DatasetDetailsProps> = ({ style, className }) => {
  const { push } = useHistory();
  const fileId = useLocation().pathname.split('/')?.[2];
  const dispatch = useDispatch();
  const remove = React.useCallback(() => {
    dispatch(dataSetsRemove(fileId || ''));
    push('/datasets');
  }, [dispatch, fileId, push]);
  const dataSet = useSelector((state: AppState) =>
    state.dataSets.dataSets.find((dataSet) => dataSet.id === fileId),
  );
  const { firstname } = useSelector((state: AppState) => state.user);
  const [dataSetSelectedColumnOptions, setDataSetSelectedColumnOptions] = React.useState<
    string[]
  >([]);

  const idIfinUploadState = useSelector((state: AppState) => state.upload[fileId]);

  React.useEffect(() => {
    if (!dataSet) {
      return;
    }

    const options: string[] = [];
    let columnLength = dataSet.sample[0]?.length || dataSet.header.length;
    if (
      columnLength === 0 &&
      dataSet.file_import_statistics &&
      Object.keys(dataSet.file_import_statistics.error_samples).length > 0
    ) {
      columnLength = Object.values(dataSet.file_import_statistics.error_samples)[0][0]
        .length;
    }

    for (let column = 0; column < columnLength; column++) {
      const keyByValue: string | undefined = Object.keys(dataSet.columnSelect).find(
        (key) => dataSet.columnSelect[key] === column,
      );

      options.push(!!keyByValue ? `${ColumnTag[parseInt(keyByValue, 10)]}` : '');
    }

    setDataSetSelectedColumnOptions(options);
  }, [dataSet]);

  if (!fileId) return null;
  if (!dataSet) return null;

  const deletableStates: DatasetItemStatus[] = [
    DatasetItemStatus.UPLOADING,
    DatasetItemStatus.ERROR,
  ];

  const optionsAvailable = columnOptions
    .sort((a, b) => (!!a.mandatory ? -1 : 1))
    .filter((option) => !option.biotype || option.biotype === dataSet.biotype)
    .map((option) => `- ${option.description}${option.mandatory ? `*` : ``}${linebreak}`);

  const handleRetaggingClick = () =>
    mailto(
      'support@knowing01.com',
      `Re-tagging request [${dataSet.id}]`,
      `Dear ${firstname},${linebreak}${linebreak}please let us know when we can reach out to you to go over the re-tagging of your file. We will send you an invite accordingly.${linebreak}We will need about 10-15' of your time. Enter your desired time here: __${linebreak}${linebreak}${linebreak}Alternatively, you can specify the re-tagging of your data set below. Simply enter the respective column headers of your file next to the desired tag in the list below. We will send you a confirmation email as soon as we're done.${linebreak}${linebreak}New Tag: insert column header/index of file${linebreak}${optionsAvailable.join(
        '',
      )}${linebreak}Please keep in mind that:${linebreak}
      (1) each tag can only be given once.${linebreak}
      (2) the tags highlighted with an asterix are mandatory (see also ${
        window.location.origin
      }/method-description).${linebreak}
      (3) not all your columns need to be tagged / tags can remain unassigned as long as they are not mandatory.${linebreak}${linebreak}${linebreak}– Your knowing01 team.`,
    );

  if (dataSet.genomeAssembly === GenomeAssembly.Unknown) {
    dataSet.genomeAssembly = undefined;
  }

  // in case we have a NOISY DATA file error we extract here the type of error:
  let mistaggedFileErrors = [];
  if (
    dataSet.file_import_statistics &&
    Object.keys(dataSet.file_import_statistics.error_summary).includes(
      ImportLineError.UNKNOWN_SIGNIFICANCE,
    )
  ) {
    mistaggedFileErrors.push(ImportLineError.UNKNOWN_SIGNIFICANCE);
  }
  if (
    dataSet.file_import_statistics &&
    Object.keys(dataSet.file_import_statistics.error_summary).includes(
      ImportLineError.INVALID_RANGE_CODING,
    )
  ) {
    mistaggedFileErrors.push(ImportLineError.INVALID_RANGE_CODING);
  }
  if (
    dataSet.file_import_statistics &&
    Object.keys(dataSet.file_import_statistics.error_summary).includes(
      ImportLineError.UNKNOWN,
    )
  ) {
    mistaggedFileErrors.push(ImportLineError.UNKNOWN);
  }

  const activateQueuedImportState = dataSet.uploadProgress === 1 ? true : false;
  const activateQueuedLinkingState =
    dataSet.importProgress === 1 && mistaggedFileErrors.length === 0 ? true : false;

  return (
    <Card style={style} className={className} key={fileId}>
      <Title>
        <Headline design={'h1'} as={'h1'}>
          {dataSet.name || dataSet.fileName || ''}
        </Headline>

        <DatasetStatus id={fileId} />
      </Title>
      {
        <>
          <Props>
            <FullDescription>{dataSet.description}</FullDescription>
            <UpdateCoreParameters dataSet={dataSet} />
            <hr />
            <div>
              <div>
                <small>
                  Biotype: <strong>{Biotype[dataSet.biotype]}</strong>
                </small>
              </div>
              {dataSet.genomeAssembly && (
                <div>
                  <small>
                    Genome assembly:{' '}
                    <strong>{GenomeAssembly[dataSet.genomeAssembly]}</strong>
                  </small>
                </div>
              )}
              {dataSet.taxonomy ? (
                <div>
                  <small>
                    Organism: <strong>{Taxonomy[dataSet.taxonomy]}</strong>
                  </small>
                </div>
              ) : (
                ''
              )}
              {dataSet.metadata_tags && (
                <div>
                  <small>
                    Tags:
                    <MetadataTagList>
                      {dataSet.metadata_tags
                        .split(';')
                        .map((tag: string) =>
                          tag.trim() !== '' ? <MetadataTag>{tag}</MetadataTag> : '',
                        )}
                    </MetadataTagList>
                  </small>
                </div>
              )}
              <div>
                <small>Filename: {dataSet.fileName}</small>
              </div>
            </div>
          </Props>
          <hr />

          <Headline design={'h2'}>Processing information</Headline>
        </>
      }
      {idIfinUploadState === undefined && dataSet.status === DatasetItemStatus.UPLOADING && (
        <>
          <WarningMessage>
            Frontend lost any information about uploading. (resume buttom should be
            non-responsive.)
          </WarningMessage>
        </>
      )}
      {idIfinUploadState && (
        <></> //<>Frontend does have a track record about the upload itself.</>
      )}
      {(dataSet.status === DatasetItemStatus.UPLOADING ||
        dataSet.status === DatasetItemStatus.UPLOADED ||
        dataSet.status === DatasetItemStatus.IMPORTING ||
        dataSet.status === DatasetItemStatus.IMPORTED ||
        dataSet.status === DatasetItemStatus.QUEUED_FOR_IMPORT ||
        dataSet.status === DatasetItemStatus.LINKING ||
        dataSet.status === DatasetItemStatus.LINKED ||
        dataSet.status === DatasetItemStatus.QUEUED_FOR_LINKING ||
        dataSet.status === DatasetItemStatus.ERROR) && (
        <div>
          <DataSetProgress>
            <ProgressContainer>
              <p>Upload@{Math.round(dataSet.uploadProgress * 10000) / 100}% </p>
              <ProgressBar
                style={{ transform: `scaleX(${dataSet.uploadProgress})` }}
                error={false}
              />
            </ProgressContainer>
            <QueuedStateContainer mark={activateQueuedImportState} />
            <ProgressContainer>
              <p>
                {mistaggedFileErrors.length > 0 &&
                dataSet.status === DatasetItemStatus.ERROR ? (
                  'Import failed'
                ) : (
                  <>Import@{Math.round(dataSet.importProgress * 10000) / 100}%</>
                )}
              </p>
              <ProgressBar
                style={{ transform: `scaleX(${dataSet.importProgress})` }}
                error={
                  mistaggedFileErrors.length > 0 &&
                  dataSet.status === DatasetItemStatus.ERROR
                }
              />
            </ProgressContainer>
            <QueuedStateContainer mark={activateQueuedLinkingState} />
            <ProgressContainer>
              <p>Link@{Math.round(dataSet.linkProgress * 10000) / 100}% </p>
              <ProgressBar
                style={{ transform: `scaleX(${dataSet.linkProgress})` }}
                error={false}
              />
            </ProgressContainer>
          </DataSetProgress>
          {dataSet.status === DatasetItemStatus.UPLOADING && (
            <p>
              Uploading file from your computer to your private repository in our cloud.
              <br />
              <>
                <DetailsUpload
                  id={dataSet.id}
                  showPause={idIfinUploadState !== undefined}
                />
              </>
            </p>
          )}
          {idIfinUploadState === undefined &&
            dataSet.status === DatasetItemStatus.UPLOADING && (
              <WarningMessage>Status of upload currently unknown.</WarningMessage>
            )}
          {dataSet.status === DatasetItemStatus.UPLOADED && (
            // will not appear as we recode uploaded into Queued for import to enhance usability
            <p>
              File was successfully uploaded to your private repository in our cloud.
              Please be patient, our machines are pushing your file along the processing
              pipeline.
            </p>
          )}
          {dataSet.status === DatasetItemStatus.QUEUED_FOR_IMPORT && (
            <p>
              File is queued for import. Please be patient, our machines are busy
              importing other files that stood earlier in line.
            </p>
          )}
          {(dataSet.status === DatasetItemStatus.IMPORTING ||
            dataSet.status === DatasetItemStatus.IMPORTED) && (
            <p>
              Our know.bot is working through the file thereby harmonizing the data for
              further use.
            </p>
          )}
          {dataSet.status === DatasetItemStatus.QUEUED_FOR_LINKING && (
            <p>
              File is queued for linking. Please be patient, our machines are busy linking
              other files that stood earlier in line.
            </p>
          )}
          {(dataSet.status === DatasetItemStatus.LINKING ||
            dataSet.status === DatasetItemStatus.LINKED) && (
            <>
              <p>
                Our know.bot is automatically linking the imported observations (rows of
                the file) to the Cellmap. Only linking to the Cellmap will allow to
                leverage the full capacity of the data translations.
              </p>
              <p>
                Linking processes are fed through a queue that may include waiting times
                when systems are busy.
              </p>
            </>
          )}
        </div>
      )}
      {dataSet.status === DatasetItemStatus.ERROR && (
        <div>
          {dataSet.file_import_statistics &&
            dataSet.file_import_statistics.error_type === ImportFileError.CSV_ERROR && (
              <>
                <ErrorMessage>
                  An error occurred <strong>during reading the file</strong>. This implies
                  a corrupt text file format, like varying number of columns in the rows.
                  <br />
                  <br />
                  We recommend to check and fix your original file, delete this file and
                  re-upload. In case you need help, we will be happy to assist you in the
                  process. Simply{' '}
                  <a href="mailto:support@knowing01.com">write an e-mail</a> to our
                  support team.
                </ErrorMessage>
              </>
            )}
          {dataSet.file_import_statistics &&
            dataSet.file_import_statistics.error_type === ImportFileError.NOISY_DATA && (
              <>
                {mistaggedFileErrors.includes(ImportLineError.UNKNOWN) ? (
                  <ErrorMessage>
                    An error occurred <strong>during file import</strong>. At least one
                    row had a variation in the number of columns. This may be a first hint
                    of corrupt data, and needs further inspection. Please check and fix
                    your original file, delete this entry and re-upload the fixed file.{' '}
                    <br />
                    <br />
                    In case you need help, we will be happy to assist you in the process.
                    Simply <a href="mailto:support@knowing01.com">write an e-mail</a> to
                    our support team.
                  </ErrorMessage>
                ) : (
                  ''
                )}

                {mistaggedFileErrors.includes(ImportLineError.UNKNOWN_SIGNIFICANCE) ||
                mistaggedFileErrors.includes(ImportLineError.INVALID_RANGE_CODING) ? (
                  <>
                    <ErrorMessage>
                      An error occurred <strong>during file import</strong> that results
                      from a potential mis-tagging of data columns.
                      <ul>
                        {mistaggedFileErrors.includes(
                          ImportLineError.UNKNOWN_SIGNIFICANCE,
                        ) ? (
                          <li>
                            Significance, adjusted significance or effect size tag did not
                            to catch numeric values.
                          </li>
                        ) : (
                          ''
                        )}
                        {mistaggedFileErrors.includes(
                          ImportLineError.INVALID_RANGE_CODING,
                        ) ? (
                          <li>
                            Genomic position cannot be derived from data as given by
                            chromosome/start/stop tags.
                          </li>
                        ) : (
                          ''
                        )}
                      </ul>
                    </ErrorMessage>
                    <p>
                      <Button onClick={handleRetaggingClick} design={ButtonDesign.LINK}>
                        Inquire per mail
                      </Button>{' '}
                      to retag your columns.
                    </p>
                    <p>
                      For your information, an excerpt of <strong>error rows</strong> with
                      tag information:
                    </p>
                    <Props>
                      <FilePreview
                        data={[
                          dataSetSelectedColumnOptions,
                          dataSet.header,
                          ...Object.values(
                            dataSet.file_import_statistics.error_samples,
                          )[0],
                        ]}
                        omitBox={true}
                      />
                    </Props>
                  </>
                ) : (
                  ''
                )}
              </>
            )}
          {(!dataSet.file_import_statistics ||
            (dataSet.file_import_statistics &&
              dataSet.file_import_statistics.error_type === ImportFileError.UNKNOWN)) && (
            // assuming this is an linking error/unspecific error
            <ErrorMessage>
              We are sorry, but an error occurred during processing your file. Our support
              team got notified and will contact you a.s.a.p. Thanks for your patience.
            </ErrorMessage>
          )}
        </div>
      )}
      {(dataSet.status === DatasetItemStatus.UPLOADING ||
        dataSet.status === DatasetItemStatus.IMPORTING ||
        dataSet.status === DatasetItemStatus.QUEUED_FOR_IMPORT ||
        dataSet.status === DatasetItemStatus.LINKING ||
        dataSet.status === DatasetItemStatus.QUEUED_FOR_LINKING) && (
        <div>
          <p>
            <small>
              In case the process is stuck, just drop us a message{' '}
              <a href="mailto:support@knowing01.com">via e-mail</a>, we get back to you
              a.s.a.p..
            </small>
          </p>
        </div>
      )}
      {dataSet.post_processing?.length > 0 && (
        <WarningMessage>
          Important notification from automatic processing
          <ul>
            {dataSet.post_processing?.map((post_processing) => (
              <li>
                {post_processing.type === PostProcessingType.LOG10TRANSFORM && (
                  <>
                    In Column{' '}
                    <b>
                      {ColumnTag[parseInt(post_processing.tag.valueOf().toString(), 10)]}
                    </b>{' '}
                    we detected values suggesting a log10 p-value transformation, which we
                    transformed back to alllow you to set significant cutoffs to commonly
                    used thresholds.
                  </>
                )}
              </li>
            ))}
          </ul>
        </WarningMessage>
      )}
      {dataSet.status === DatasetItemStatus.INTEGRATED && (
        <>
          <p>
            We extracted {dataSet.statistics.data_count} observations from the file.
            know.bot was able to link {dataSet.statistics.data_linked_count} observations{' '}
            {!!dataSet.statistics.data_count &&
              `(${
                Math.round(
                  (dataSet.statistics.data_linked_count / dataSet.statistics.data_count) *
                    10000,
                ) / 100
              }%)`}{' '}
            to the Cellmap, by using information of the tagged fields.
          </p>
          {dataSet.statistics.data_linked_count === 0 && (
            <WarningMessage>
              No observation got linked to the Cellmap. knowing01@Support gets
              automatically informed about this and will be in touch with you. In case you
              want to contact us right away please{' '}
              <a href="mailto:support@knowing01.com">drop us a mail</a>.
            </WarningMessage>
          )}
          <hr />

          {!!(dataSet.available_cutoffs.length || dataSet.has_fake_significance) && (
            <>
              <DatasetCutoff dataSet={dataSet} />
              <hr />
            </>
          )}

          <Headline design={'h2'}>Tagged columns</Headline>
          <p>
            Selected <b>tags</b> are displayed together with the top 5 lines of your file.{' '}
            <Button onClick={handleRetaggingClick} design={ButtonDesign.LINK}>
              Inquire per mail
            </Button>{' '}
            to retag your columns.
          </p>

          <Props>
            <FilePreview
              data={[dataSetSelectedColumnOptions, dataSet.header, ...dataSet.sample]}
              omitBox={true}
            />
          </Props>
        </>
      )}
      {dataSet && deletableStates.includes(dataSet.status) && (
        <p>
          <Button marginTop={2} design={ButtonDesign.DANGER} onClick={remove}>
            Delete file
          </Button>
        </p>
      )}
    </Card>
  );
};

DatasetDetails.displayName = 'DatasetDetails';
