import styled from 'styled-components';
import {
  compose,
  layout,
  space,
  grid,
  position,
  color,
  alignContent,
  alignItems,
  border,
  flexbox,
} from 'styled-system';

import * as T from './_types';

export const Box = styled.div<T.BoxProps>`
  box-sizing: border-box;
  ${compose(layout, grid, space, position, color, flexbox, alignContent, alignItems, border)};
`;

Box.displayName = 'Box';
