import * as React from 'react';

import { Box } from '../../layout';

type WrapperProps = {
  children: React.ReactNode;
  data: string[][];
  condition: boolean;
};

const Wrapper = ({ children, data, condition }: WrapperProps) => {
  const columns = (data || []).reduce<number>(
    (acc: number, columns: string[]) => Math.max(columns.length, acc),
    0,
  );

  if (!condition) {
    return <>{children}</>;
  }

  return (
    <Box display="grid" gridTemplateColumns={`repeat(${columns}, 1fr)`} gridGap={1}>
      {children}
    </Box>
  );
};

type FilePreviewProps = {
  data: string[][];
  omitBox: boolean;
};

export const FilePreview = ({ data, omitBox }: FilePreviewProps) => {
  return (
    <Wrapper data={data} condition={omitBox}>
      {data &&
        data.map((entries, rowIndex) => (
          <React.Fragment key={rowIndex}>
            {entries.map((entry, columnIndex) => (
              <Box
                key={entry + ':' + columnIndex}
                gridColumn={`${columnIndex + 1}/${columnIndex + 2}`}
                padding={2}
                backgroundColor={rowIndex === 0 ? '#F7F8F8' : '#FAFBFB'}
                marginBottom={omitBox && rowIndex === 0 ? 2 : 0}
              >
                {omitBox && rowIndex === 0 ? <strong>{entry}</strong> : entry}
              </Box>
            ))}
          </React.Fragment>
        ))}
    </Wrapper>
  );
};

FilePreview.defaultProps = {
  omitBox: false,
};
