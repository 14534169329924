import styled from 'styled-components';

export const UploadPercentage = styled.span`
  margin: auto auto auto 8px;
  display: flex;
  place-items: center;
  font-weight: bold;
  position: relative;
`;

export const UploadBar = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform-origin: 0 0;
  background: black;
  opacity: .1;
  transition: transform 1700ms linear;
`;

export const Upload = styled.div`
  position: relative;
  display: flex;
  padding: 2px;
  background: #e9e9e9;
  border-radius: 3px;
  overflow: hidden;
`;
