import * as React from 'react';

import { RouteSwitch } from './RouteSwitch';
import { Frame } from './Frame';
import { useLeaveWarning } from './App.effects';

const App: React.FC = () => {
  useLeaveWarning();
  return (
    <Frame>
      <RouteSwitch />
    </Frame>
  );
}

export default App;
