import { DatasetSelection } from '../store';
import { ExperimentQuery, ExperimentQueryItem } from '../components/CellMap';

const selectionStateToQuery: (selection: DatasetSelection[]) => ExperimentQuery = (
  selection,
) => {
  return {
    items: selection.map((selection) => {
      const item: ExperimentQueryItem = {
        experiment_id: selection.id,
        operation_type: selection.overlapType,
        cutoff_type: selection.cutoffType,
      };

      return item;
    }),
  };
};

export default selectionStateToQuery;
