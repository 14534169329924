import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  margin: 8px 0 4px;

  > :nth-child(1) {
    flex-shrink: 0;
    margin: auto 8px auto 0;
  }

  > :nth-child(2) {
    flex-grow: 1;
  }
`;
