import * as React from 'react';

export interface UploadProps {
  style?: React.CSSProperties;
  className?: string;
}

export enum Biotype {
  'Unknown' = 0,
  'Gene' = 1,
  'Gene Symbol' = 2,
  'Transcript' = 3,
  'Protein' = 4,
  'Variant' = 5,
  'Genomic Range' = 6,
}

export enum GenomeAssembly {
  'Unknown' = 0,
  'GRCh37/hg19' = 1,
  'GRCh38' = 2,
  'GRChm38' = 3,
  'RNOR_6_0' = 4,
}

export enum Taxonomy {
  'Unknown' = 0,
  'human (taxID:9606)' = 1,
  'mouse (taxID:10096)' = 2,
  'rat (taxID:10116)' = 3,
}
