import styled, { css, keyframes } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Link } from 'react-router-dom';

import * as T from './_types';

const flash = keyframes`
  0% { background-color: lightyellow; }
  50% { background-color: white; }
  100% { background-color: lightyellow; }
`;

export const Title = styled.div`
  opacity: 0.5;
  flex-grow: 1;
  word-break: break-all;
  word-wrap: break-word;
  min-width: 50%;
`;

export const Button = styled(Link)`
  width: 85%;
  border: 0;
  padding: 16px 18px;
  background: transparent;
  text-align: left;
  display: flex;
  flex-direction: row;
  position: relative;
  font-style: normal;
  box-sizing: border-box;
  white-space: normal;

  :hover {
    border-bottom: 0;
  }
`;

export const Boxed = styled.div<T.ButtonProps>`
  display: flex;
  border-top: 1px solid ${themeGet('colors.grey90')};
  padding-left: 5px;

  ${(props) =>
    props.selected &&
    css`
      background: ${themeGet('colors.grey90')};
    `}

  ${(props) =>
    props.isHighlighted &&
    css`
      animation: ${flash} 2s ease-in-out infinite;
    `}
`;
