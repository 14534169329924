import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import {
  compose,
  gridArea,
  GridAreaProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system';

export const ErrorMessage = styled.p<LayoutProps & GridAreaProps & SpaceProps>`
  color: #333;
  margin: 1rem;
  padding: 0.5rem;
  border-radius: 5px;
  background: ${themeGet('colors.error')};
  ${compose(layout, space, gridArea)};
`;

export const WarningMessage = styled.p<LayoutProps & GridAreaProps & SpaceProps>`
  color: #333;
  margin: 1rem;
  padding: 0.5rem;
  border-radius: 5px;
  background: #ffde9c;
  ${compose(layout, space, gridArea)};
`;

export const InfoMessage = styled.p<LayoutProps & GridAreaProps & SpaceProps>`
  margin: 0;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background: #e1fff3;
  width: auto;
  ${compose(layout, space, gridArea)};
`;
