import React from 'react';
import styled, { css } from 'styled-components';
import { variant } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

const padCSS = css`
  &:first-child {
    padding-left: ${themeGet('space.1')};
  }

  &:last-child {
    padding-right: ${themeGet('space.1')};
  }
`;

const cellCSS = css<{ noPaddingHorizontal?: boolean; noPaddingVertical?: boolean }>`
  position: relative;
  vertical-align: top;

  padding-top: ${(props) => (!!props.noPaddingVertical ? 0 : themeGet('space.1'))};
  padding-bottom: ${(props) => (!!props.noPaddingVertical ? 0 : themeGet('space.1'))};
  padding-left: ${themeGet('space.1')};
  padding-right: ${themeGet('space.2')};
  border-top: 1px solid #eee;

  text-align: left;
  font-weight: normal;
  word-wrap: break-word;

  ${(props) => !props.noPaddingHorizontal && padCSS}
`;

export const HeadCell = styled.th<{
  noPaddingHorizontal?: boolean;
  noPaddingVertical?: boolean;
}>`
  ${cellCSS};
  font-weight: 600;
  border-top: 0;
  vertical-align: bottom;
`;

export const Cell = styled.td<{
  noPaddingHorizontal?: boolean;
  noPaddingVertical?: boolean;
}>`
  ${cellCSS};
`;

export const Row = styled.tr<{ back?: 'normal' | 'alt' | 'none' }>`
  background-color: ${themeGet('colors.rowBack')};
  ${variant({
    key: 'back',
    variants: {
      none: { backgroundColor: 'transparent' },
      alt: { backgroundColor: 'rowBackAlt' },
    },
  })};
`;

const TableContainer = styled.table<{ smallFont: boolean }>`
  font-size: ${(props) => (props.smallFont ? '.75rem' : '.875rem')};
  line-height: 1.5;
  table-layout: fixed;
  margin-top: ${themeGet('space.2')};
`;

type TableRootProps = {
  children: React.ReactNode;
  smallFont?: boolean;
  className?: string;
};

export const TableRoot = ({ children, smallFont = false, className }: TableRootProps) => (
  <TableContainer
    cellPadding={0}
    cellSpacing={0}
    smallFont={smallFont}
    className={className}
  >
    <tbody>{children}</tbody>
  </TableContainer>
);
