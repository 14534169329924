import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as T from './_types';

import { AppState, dataSetsLoad } from '../../../store';
import { userDataLoad } from '../../../store/user';

export const useDatasets: T.DatasetsHook = () => {
  const selection = useLocation().pathname.split('/')?.[2];
  const { errorMessage, dataSets: datasets = [] as T.Dataset[], loading } = useSelector(
    (state: AppState) => state.dataSets,
  );
  const {
    credits_left: uploadFileUsageLeft,
    credits_total: uploadFileLimit,
  } = useSelector(
    (state: AppState) => state.user?.customer || { credits_left: 0, credits_total: 0 },
  );
  const uploadFileUsage = uploadFileLimit - uploadFileUsageLeft;
  const dispatch = useDispatch();

  React.useEffect(() => void dispatch(dataSetsLoad()), [dispatch]);
  React.useEffect(() => void dispatch(userDataLoad()), [dispatch, datasets.length]);

  return {
    datasets,
    loading,
    errorMessage,
    selection,
    uploadFileUsage,
    uploadFileLimit,
  };
};
