/*
 * action types
 */

export const SHOW_TOOLTIPS = 'SHOW_TOOLTIPS';
export const HIDE_TOOLTIPS = 'HIDE_TOOLTIPS';

/*
 * action creators
 */

export const hideTooltips = () => ({
  type: HIDE_TOOLTIPS,
});

export const showTooltips = () => ({
  type: SHOW_TOOLTIPS,
});

const defaultState = {
  showTooltips: true,
};

const reducer = (state = defaultState, { type }) => {
  switch (type) {
    case SHOW_TOOLTIPS:
      return {
        ...state,
        showTooltips: true,
      };
    case HIDE_TOOLTIPS:
      return {
        ...state,
        showTooltips: false,
      };
    default:
      return state;
  }
};

export default reducer;
