import { AnyAction, AppState } from './_types';
import { call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { RequestRaw, RequestStatus } from '../components/CellMap';
import { getUserRequestList } from '../services/api';
import { fetchExperimentList } from './experiments';

export const loadUserRequest = () => ({
  type: 'GET_ALL_USER_REQUESTS',
  payload: {},
});

export const requestLoadSuccess = (requests: RequestRaw[]) => ({
  type: 'REQUEST_LOAD_SUCCESS',
  payload: { requests },
});

export const requestsLoadError = (errorMessage: string) => ({
  type: 'REQUESTS_LOAD_ERROR',
  payload: { errorMessage },
});

export const resetUserRequestState = () => ({
  type: 'RESET_USER_REQUEST_STATE',
});

// export type RequestAction =
//   | ReturnType<typeof loadUserRequest>
//   | ReturnType<typeof requestLoadSuccess>
//   | ReturnType<typeof requestsLoadError>
//   | ReturnType<typeof resetUserRequestState>;

export interface UserRequestState {
  loading: boolean;
  errorMessage?: string;
  requests: RequestRaw[];
}

const defaultState: UserRequestState = {
  loading: false,
  requests: [],
};

// Reducer
const reducer = (
  state: UserRequestState = defaultState,
  action: AnyAction,
): UserRequestState => {
  switch (action.type) {
    case 'GET_ALL_USER_REQUESTS':
      return {
        ...state,
        loading: true,
      };
    case 'REQUEST_LOAD_SUCCESS': {
      const {
        payload: { requests },
      } = action as ReturnType<typeof requestLoadSuccess>;
      return {
        ...state,
        errorMessage: undefined,
        loading: false,
        requests,
      };
    }
    case 'REQUEST_LOAD_ERROR': {
      const {
        payload: { errorMessage },
      } = action as ReturnType<typeof requestsLoadError>;
      return {
        ...state,
        loading: false,
        errorMessage,
      };
    }
    case 'RESET_USER_REQUEST_STATE': {
      return defaultState;
    }
    default:
      return state;
  }
};
export default reducer;

function* handleRequestLoad() {
  const idToken = yield select((state: AppState) => state.auth.idToken);
  try {
    const exp = yield select((state: AppState) => state.experiments);
    if (!exp.experimentList) {
      // important to load the experiment info, to have the names all ready to display export requests.
      yield put(fetchExperimentList());
    }
    const requestList = yield call(getUserRequestList, idToken);
    yield put(requestLoadSuccess(requestList));
  } catch (e) {
    yield put(requestsLoadError(e.message || e.errorMessage || 'Unknown error'));
  }
}

function* watchRequestLoad() {
  yield takeEvery('GET_ALL_USER_REQUESTS', handleRequestLoad);
}

let request_update_in_stale_mode = false;
const REQUEST_UPDATE_DELAY = 5000;
const REQUEST_FETCH_MULTIPLE_ACTIVE = 2;
const REQUEST_FETCH_MULTIPLE_STALE = 9;
let request_update_counter = 0;
let freeToGoFetchingRequest = true;

function* periodicallyUpdateRequests() {
  while (true) {
    const isLoggedIn = yield select((state: AppState) => state.auth.isLoggedIn);
    if (isLoggedIn) {
      const isProcessingRequests = yield select((state: AppState) =>
        state.requests.requests.some(
          (request) =>
            request.state !== RequestStatus.PROCESSING &&
            request.state !== RequestStatus.NEW &&
            request.state !== RequestStatus.QUEUED,
        ),
      );
      request_update_in_stale_mode = isProcessingRequests === false;
      // delay depending on the status
      if (
        (request_update_in_stale_mode && isProcessingRequests === true) ||
        (isProcessingRequests === true &&
          request_update_counter === REQUEST_FETCH_MULTIPLE_ACTIVE) ||
        request_update_counter === REQUEST_FETCH_MULTIPLE_STALE
      ) {
        if (freeToGoFetchingRequest) {
          freeToGoFetchingRequest = false;
          yield put(loadUserRequest());
        }

        request_update_counter = 0;
      }
      request_update_counter += 1;
      yield delay(REQUEST_UPDATE_DELAY);
    } else {
      yield delay(REQUEST_UPDATE_DELAY);
    }
  }
}

export const requestSagas = [watchRequestLoad(), periodicallyUpdateRequests()];
