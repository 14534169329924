import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import { ExperimentCutoffType, setCutoff } from '../../../../store';
import { Button, ButtonDesign } from '../../../Button';
import { ExperimentCutoff } from '../../../CellMap';
import { ErrorMessage } from '../../../text/ErrorMessage';

const Container = styled.div`
  display: flex;
  margin-bottom: ${themeGet('space.4')};
`;

const Left = styled.div`
  padding: 0 ${themeGet('space.4')};

  > form {
    padding: 0;
  }
`;

const Right = styled.div`
  margin-left: ${themeGet('space.0')};
`;

const Title = styled.div<{ showMarginLeft?: boolean }>`
  margin-bottom: ${themeGet('space.2')};
  margin-left: ${(props) => (props.showMarginLeft ? `21px` : 0)};
`;

const CutoffType = styled.span`
  text-transform: capitalize;
`;

const Item = styled.div`
  display: flex;
  height: 20px;
  margin-bottom: ${themeGet('space.1')};

  line-height: 20px;

  &:last-of-type {
    margin-bottom: ${themeGet('space.3')};
  }
`;

const Bar = styled.div<{ widthMultiplier: number }>`
  box-sizing: border-box;
  display: block;
  flex-basis: calc(${(props) => props.widthMultiplier * 100}%);
  flex-shrink: 0;
  height: 100%;
  margin-right: ${themeGet('space.2')};

  border: 1px solid ${themeGet('colors.grey60')};
  background-color: ${themeGet('colors.grey90')};
`;

type FormValues = {
  cutoffIndex: string;
};

type DatasetCutoffFormProps = {
  cutoffType: ExperimentCutoffType;
  experimentId: string;
  cutoffs: ExperimentCutoff[];
  selectedCutoff: ExperimentCutoff;
  allowEdit: boolean | null;
};

export const DatasetCutoffForm = ({
  cutoffType,
  experimentId,
  cutoffs,
  selectedCutoff,
  allowEdit,
}: DatasetCutoffFormProps) => {
  const selectedCutoffIndex = cutoffs.findIndex(
    (cutoff) => cutoff.cutoff === selectedCutoff.cutoff,
  );
  const [isFormVisible, setIsFormVisible] = React.useState<boolean>(false);
  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      cutoffIndex:
        selectedCutoffIndex !== -1
          ? selectedCutoffIndex.toString()
          : (cutoffs.length - 1).toString(),
    },
  });
  const dispatch = useDispatch();

  const onSubmit = handleSubmit((data) => {
    dispatch(setCutoff(cutoffType, experimentId, parseInt(data.cutoffIndex, 0)));
    setIsFormVisible(false);
  });

  const cutoffTypeName = ExperimentCutoffType[cutoffType].toLowerCase();

  // USING THIS FOR CORRECT AND ADAPTED WORDING OF THE CUTOFFS
  const significanceTypeNames = [
    'Unknown',
    'Adjusted Significance',
    'Significance',
    'Score',
    'Absolute Effect Size',
  ];
  const significanceTypeCorrectComparator = ['<', '<', '<', '>', '>'];
  const helper_text = ['', '', '', 'Top ', 'Top '];
  const helper_convert_to_percentage = [false, false, false, true, true];

  const cutoffAmountMax: number = cutoffs.reduce(
    (max, cutoff) => (cutoff.amount > max ? cutoff.amount : max),
    cutoffs[0].amount,
  );

  return (
    <Container>
      <Left>
        <Title showMarginLeft={isFormVisible}>
          <strong>
            <CutoffType>{cutoffTypeName}</CutoffType> cutoff
          </strong>
        </Title>

        {!isFormVisible && (
          <>
            <Item>
              {`${significanceTypeNames[selectedCutoff.type]} 
              ${significanceTypeCorrectComparator[selectedCutoff.type]} 
              ${helper_text[selectedCutoff.type]} 
              ${
                helper_convert_to_percentage[selectedCutoff.type]
                  ? selectedCutoff.cutoff * 100 + '%'
                  : selectedCutoff.cutoff
              }`}
            </Item>

            {allowEdit && (
              <Button type="button" onClick={() => setIsFormVisible(true)}>
                Change Cutoff
              </Button>
            )}
          </>
        )}

        <form onSubmit={onSubmit} style={{ display: isFormVisible ? 'block' : 'none' }}>
          {cutoffs.map((cutoff, index) => {
            const id = index.toString();
            const name = `${significanceTypeNames[cutoff.type]} < ${cutoff.cutoff}`;

            return (
              <Item key={index}>
                <input
                  ref={register}
                  type="radio"
                  id={`${cutoffTypeName}-${id}`}
                  name="cutoffIndex"
                  value={index}
                />
                <label htmlFor={`${cutoffTypeName}-${id}`}>{name}</label>
              </Item>
            );
          })}

          {selectedCutoffIndex < 0 && (
            <ErrorMessage margin={0} marginBottom={3}>
              [Warning] The currently selected cutoff value is not available for selection
              any more. We pre-selected another cutoff for you. Please cancel to keep the
              existing cutoff.
            </ErrorMessage>
          )}

          <Button type="submit">Update Cutoff</Button>
          <Button
            type="button"
            design={ButtonDesign.LINK}
            onClick={() => setIsFormVisible(false)}
            marginLeft={3}
          >
            Cancel
          </Button>
        </form>
      </Left>

      <Right>
        <Title>
          <strong>No. observations matching cutoff</strong>
        </Title>

        {!isFormVisible && (
          <Item>
            <Bar widthMultiplier={selectedCutoff.amount / cutoffAmountMax} />
            <div>{selectedCutoff.amount}</div>
          </Item>
        )}

        {isFormVisible &&
          cutoffs.map((cutoff, index) => (
            <Item key={index}>
              <Bar widthMultiplier={cutoff.amount / cutoffAmountMax} />
              <div>{cutoff.amount}</div>
            </Item>
          ))}
      </Right>
    </Container>
  );
};
