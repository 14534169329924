import * as React from 'react';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { SpaceProps, GridAreaProps, LayoutProps } from 'styled-system';

import * as S from './_style';
import * as T from './_types';

interface ButtonProps extends SpaceProps, GridAreaProps, LayoutProps {
  style?: React.CSSProperties;
  design?: T.ButtonDesign;
  focused?: boolean;
  hovered?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  label?: string;
  faIcon?: string;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  spinIcon?: boolean;
  loading?: boolean;
  tabIndex?: number;
}

export const Button: React.FC<ButtonProps> = ({
  style,
  className,
  disabled = false,
  onClick,
  label,
  faIcon,
  type = 'button',
  design = T.ButtonDesign.DEFAULT,
  spinIcon = false,
  hovered = false,
  focused = false,
  loading = false,
  tabIndex,
  children,
  ...props
}) => (
  <S.ButtonContainer
    style={style}
    className={className}
    onClick={onClick}
    disabled={disabled}
    focused={focused}
    hovered={hovered}
    type={type}
    design={design}
    tabIndex={tabIndex}
    {...props}
  >
    {/*
    // @ts-ignore */}
    {faIcon && <S.FontAwesomeIcon icon={icons[faIcon]} spin={spinIcon} />}
    <S.Label fade={loading}>
      {label}
      {children}
    </S.Label>
    {loading && <S.Loading icon={icons['faCircleNotch']} spin />}
  </S.ButtonContainer>
);
