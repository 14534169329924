import styled, { css } from 'styled-components';
import { compose, gridArea, layout, space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

import * as T from './_types';

export const Container = styled.div<T.ContainerProps>`
  position: relative;

  display: flex;
  margin-bottom: ${themeGet('space.1')};
  padding: ${themeGet('space.1')};

  cursor: pointer;
  min-height: 74px;
  color: ${themeGet('colors.grey60')};
  border: 1px solid;
  border-radius: ${themeGet('radii.0')};
  transition: color 250ms ease-out;

  ${compose(layout, gridArea, space)};

  > :nth-child(n + 3) {
    margin-left: ${themeGet('space.1')};
  }

  ${(props) =>
    props.empty &&
    css`
      place-items: center;
      place-content: center;
    `};

  @media (hover: hover) {
    :hover {
      color: ${themeGet('colors.grey20')};
    }
  }
`;

export const Input = styled.input`
  appearance: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  opacity: 0;
  z-index: 2;

  :not(:disabled) {
    cursor: pointer;
  }
`;

export const UploadLabel = styled.div`
  text-align: center;
`;
