import * as React from 'react';

import * as S from './_style';
import * as T from './_types';

import { DatasetItemStatus } from '../_types';
import { IconBug, IconOK, IconProcessing } from '../../../icons';

export const DatasetStatusVisual: React.FC<T.DatasetStatusVisualProps> = ({
  style,
  className,
  status,
  reduced,
}) => (
  <>
    {reduced === true && (
      <S.StatusLabel
        style={style}
        className={className}
        status={status}
        reduced={reduced}
      >
        {status === DatasetItemStatus.INTEGRATED && <IconOK />}
        {status === DatasetItemStatus.ERROR && <IconBug />}
        {status === DatasetItemStatus.LINKING && <IconProcessing />}
      </S.StatusLabel>
    )}
    {reduced === false && (
      <S.StatusLabel
        style={style}
        className={className}
        status={status}
        reduced={reduced}
      >
        {DatasetItemStatus[status].split('_').join(' ').toLowerCase()}
        {status === DatasetItemStatus.UPLOADING ||
          status === DatasetItemStatus.IMPORTING ||
          status === DatasetItemStatus.LINKING}
      </S.StatusLabel>
    )}
  </>
);

DatasetStatusVisual.displayName = 'DatasetStatusVisual';
