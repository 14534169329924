import * as React from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import * as S from './_style';
import * as T from './_types';
import * as E from './_effects';

export const NativeSelect = React.forwardRef<HTMLSelectElement, T.SelectProps>(
  (
    {
      style,
      className,
      children,
      hideInvalid = false,
      value,
      margin,
      marginTop,
      mt,
      marginRight,
      m,
      marginBottom,
      mb,
      marginLeft,
      ml,
      marginX,
      mx,
      marginY,
      my,
      padding,
      p,
      paddingTop,
      pt,
      paddingRight,
      pr,
      paddingBottom,
      pb,
      paddingLeft,
      pl,
      paddingX,
      px,
      paddingY,
      py,
      width,
      height,
      display,
      minWidth,
      minHeight,
      maxWidth,
      maxHeight,
      size,
      verticalAlign,
      overflow,
      overflowX,
      overflowY,
      gridArea,
      ...htmlAttributes
    },
    ref,
  ) => {
    const { label, selectRef } = E.useSelect(ref, value);
    const styledSystemProps = {
      margin,
      marginTop,
      mt,
      marginRight,
      m,
      marginBottom,
      mb,
      marginLeft,
      ml,
      marginX,
      mx,
      marginY,
      my,
      padding,
      p,
      paddingTop,
      pt,
      paddingRight,
      pr,
      paddingBottom,
      pb,
      paddingLeft,
      pl,
      paddingX,
      px,
      paddingY,
      py,
      width,
      height,
      display,
      minWidth,
      minHeight,
      maxWidth,
      maxHeight,
      size,
      verticalAlign,
      overflow,
      overflowX,
      overflowY,
      gridArea,
    };
    return (
      <S.Container
        style={style}
        className={className}
        {...styledSystemProps}
      >
        <S.Border
          hideInvalid={hideInvalid}
        >
          <span>
            {label}
          </span>
          <S.Chevron icon={faChevronDown} />
        </S.Border>
        <S.Select
          ref={selectRef}
          value={value}
          {...htmlAttributes}
        >
          {children}
        </S.Select>
      </S.Container>
    );
  },
);
