import { all } from 'redux-saga/effects';

import { authSagas } from './auth';
import { dataSetsSagas } from './data-sets';
import { experimentSagas } from './experiments';
import { requestSagas } from './userRequests';
import { searchSagas } from './search';
import { annotateSagas } from './annotate';
import { selectorBoxSagas } from './selection';
import { uploadSagas } from './upload';
import { userSagas } from './user';

export function* rootSaga() {
  yield all([
    ...authSagas,
    ...dataSetsSagas,
    ...experimentSagas,
    ...requestSagas,
    ...searchSagas,
    ...annotateSagas,
    ...selectorBoxSagas,
    ...uploadSagas,
    ...userSagas,
  ]);
}
