import { routing } from '../../../utils';

// TODO: As soon as Suspense is ready for production, ditch this and use Suspense
export const load = {
  Components: routing.Loader({
    loader: () => import('../../Pages/ComponentLibrary'),
    modules: ['../../Pages/ComponentLibrary/index'],
    webpack: () => [require.resolveWeak('../../Pages/ComponentLibrary/index')],
  }),
  Home: routing.Loader({
    loader: () => import('../../Pages/Home'),
    modules: ['../../Pages/Home/index'],
    webpack: () => [require.resolveWeak('../../Pages/Home/index')],
  }),
  Explore: routing.Loader({
    loader: () => import('../../Pages/Explore'),
    modules: ['../../Pages/Explore/index'],
    webpack: () => [require.resolveWeak('../../Pages/Explore/index')],
  }),
  Datasets: routing.Loader({
    loader: () => import('../../Pages/Datasets'),
    modules: ['../../Pages/Datasets/index'],
    webpack: () => [require.resolveWeak('../../Pages/Datasets/index')],
  }),
  Account: routing.Loader({
    loader: () => import('../../Pages/Account'),
    modules: ['../../Pages/Account/index'],
    webpack: () => [require.resolveWeak('../../Pages/Account/index')],
  }),
  Login: routing.Loader({
    loader: () => import('../../Pages/Login'),
    modules: ['../../Pages/Login/index'],
    webpack: () => [require.resolveWeak('../../Pages/Login/index')],
  }),
  Logout: routing.Loader({
    loader: () => import('../../Pages/Logout'),
    modules: ['../../Pages/Logout/index'],
    webpack: () => [require.resolveWeak('../../Pages/Logout/index')],
  }),
  Register: routing.Loader({
    loader: () => import('../../Pages/Register'),
    modules: ['../../Pages/Register/index'],
    webpack: () => [require.resolveWeak('../../Pages/Register/index')],
  }),
  RestorePassword: routing.Loader({
    loader: () => import('../../Pages/RestorePassword'),
    modules: ['../../Pages/RestorePassword/index'],
    webpack: () => [require.resolveWeak('../../Pages/RestorePassword/index')],
  }),
  SetPassword: routing.Loader({
    loader: () => import('../../Pages/SetPassword'),
    modules: ['../../Pages/SetPassword/index'],
    webpack: () => [require.resolveWeak('../../Pages/SetPassword/index')],
  }),
  TermsAndConditions: routing.Loader({
    loader: () => import('../../Pages/TermsAndConditions'),
    modules: ['../../Pages/TermsAndConditions/index'],
    webpack: () => [require.resolveWeak('../../Pages/TermsAndConditions/index')],
  }),
  PrivacyPolicy: routing.Loader({
    loader: () => import('../../Pages/PrivacyPolicy'),
    modules: ['../../Pages/PrivacyPolicy/index'],
    webpack: () => [require.resolveWeak('../../Pages/PrivacyPolicy/index')],
  }),
  MethodDescription: routing.Loader({
    loader: () => import('../../Pages/MethodDescription/MethodDescription'),
    modules: ['../../Pages/MethodDescription/MethodDescription'],
    webpack: () => [
      require.resolveWeak('../../Pages/MethodDescription/MethodDescription'),
    ],
  }),
  DownloadCenter: routing.Loader({
    loader: () => import('../../Pages/DownloadCenter'),
    modules: ['../../Pages/DownloadCenter/index'],
    webpack: () => [require.resolveWeak('../../Pages/DownloadCenter/index')],
  }),
  SearchCenter: routing.Loader({
    loader: () => import('../../Pages/Search'),
    modules: ['../../Pages/Search/index'],
    webpack: () => [require.resolveWeak('../../Pages/Search/index')],
  }),
  Error404: routing.Loader({
    loader: () => import('../../Pages/Error404'),
    modules: ['../../Pages/Error404'],
    webpack: () => [require.resolveWeak('../../Pages/Error404')],
  }),
};
