import * as React from 'react';

import { Biotype, GenomeAssembly, Taxonomy } from './Upload/_types';
import {
  ExperimentCutoff,
  ExperimentStatistics,
  FileImportStatistics,
  PostProcessingInfo,
} from '../../CellMap';

export enum DatasetItemStatus {
  UNKNOWN,
  UPLOADING,
  QUEUED_FOR_IMPORT,
  IMPORTING,
  INTEGRATED,
  ERROR,
  DELETED,
  QUEUED_FOR_LINKING,
  LINKING,
  UPLOADED,
  IMPORTED,
  LINKED,
}

export enum ImportFileError {
  NONE, // = 0 No error
  UNKNOWN, // = 1 Unspecified error
  NOISY_DATA, // = 2 More than x% of data has errors
  CSV_ERROR, // = 3 File cannot be read by python csv module
}

export enum ImportLineError {
  UNKNOWN = '0', // = 0 Unspecified error
  INVALID_RANGE_CODING = '1', // = 1 Error in chromosome/position/start / stop
  UNKNOWN_SIGNIFICANCE = '2', //  = 2  # Error in significance / adjusted significance / effect siz
}

export interface DatasetsProps {
  style?: React.CSSProperties;
  className?: string;
}

export interface DatasetsHook {
  (): {
    datasets?: Dataset[];
    errorMessage?: string;
    loading: boolean;
    selection?: string;
    uploadFileUsage?: number;
    uploadFileLimit?: number;
  };
}

export interface Dataset {
  id: string;
  fileName: string;
  status: DatasetItemStatus;
  importProgress: number;
  linkProgress: number;
  uploadProgress: number;
  name: string;
  description: string;
  biotype: Biotype;
  header: string[];
  sample: string[][];
  columnSelect: { [key: string]: number };
  genomeAssembly?: GenomeAssembly;
  taxonomy?: Taxonomy;
  cutoff_lax: ExperimentCutoff;
  cutoff_stringent: ExperimentCutoff;
  available_cutoffs: ExperimentCutoff[];
  has_fake_significance?: boolean;
  experiment_id: string;
  statistics: ExperimentStatistics;
  file_import_statistics: FileImportStatistics;
  favorite: boolean;
  post_processing: PostProcessingInfo[];
  metadata_tags?: string;
}
