import { call, put, select, takeEvery } from 'redux-saga/effects';

import { AppState } from '../store';
import { passwordChange, readUserData } from '../services/api';

export interface User {
  firstname: string;
  lastname: string;
  username: string;
  customer: Customer | undefined;

  loading?: boolean;
  errorMessage?: string;

  passwordChangeState: {
    loading?: boolean;
    success?: boolean;
    errorMessage?: string;
  };

  // TODO #6: Handle when provided by API
  email?: string;
  emailVerified?: string;
  contactAgreement?: string;
}

export interface Customer {
  id: string;
  name: string;
  credits_left: number;
  credits_total: number;
}

// Actions
export const userPasswordChange = (passwordNew: string, passwordOld: string) => ({
  type: 'USER_PASSWORD_CHANGE',
  payload: { passwordNew, passwordOld },
});

export const userPasswordChangeError = (errorMessage: string) => ({
  type: 'USER_PASSWORD_CHANGE_ERROR',
  payload: { errorMessage },
});

export const userPasswordChangeSuccess = () => ({
  type: 'USER_PASSWORD_CHANGE_SUCCESS',
});

export const userDataLoad = () => ({
  type: 'USER_DATA_LOAD',
});

export const userDataLoadError = (errorMessage: string) => ({
  type: 'USER_DATA_LOAD_ERROR',
  payload: { errorMessage },
});

export const userDataLoadSuccess = (user: User) => ({
  type: 'USER_DATA_LOAD_SUCCESS',
  payload: { user },
});

export const resetUserState = () => ({
  type: 'RESET_USER_STATE',
});

export type UserAction =
  | ReturnType<typeof userDataLoad>
  | ReturnType<typeof userDataLoadError>
  | ReturnType<typeof userDataLoadSuccess>
  | ReturnType<typeof userPasswordChange>
  | ReturnType<typeof userPasswordChangeError>
  | ReturnType<typeof userPasswordChangeSuccess>
  | ReturnType<typeof resetUserState>;

// Reducer
const defaultState: User = {
  firstname: '',
  lastname: '',
  username: '',
  customer: undefined,
  passwordChangeState: {},
};

const reducer = (state: User = defaultState, action: UserAction) => {
  switch (action.type) {
    case 'USER_PASSWORD_CHANGE':
      return {
        ...state,
        passwordChangeState: {
          loading: true,
        },
      };
    case 'USER_PASSWORD_CHANGE_ERROR': {
      const {
        payload: { errorMessage },
      } = action as ReturnType<typeof userPasswordChangeError>;
      return {
        ...state,
        passwordChangeState: {
          loading: false,
          errorMessage,
        },
      };
    }
    case 'USER_PASSWORD_CHANGE_SUCCESS': {
      return {
        ...state,
        passwordChangeState: {
          loading: false,
          success: true,
        },
      };
    }
    case 'USER_DATA_LOAD':
      return {
        ...state,
        loading: true,
      };
    case 'USER_DATA_LOAD_ERROR': {
      const {
        payload: { errorMessage },
      } = action as ReturnType<typeof userDataLoadError>;
      return {
        ...state,
        loading: false,
        errorMessage,
      };
    }
    case 'USER_DATA_LOAD_SUCCESS': {
      const {
        payload: { user },
      } = action as ReturnType<typeof userDataLoadSuccess>;
      return {
        ...state,
        loading: false,
        errorMessage: undefined,
        ...user,
      };
    }
    case 'RESET_USER_STATE':
      return defaultState;
    default:
      return state;
  }
};

export default reducer;

// Sagas
function* handlePasswordChange(action: ReturnType<typeof userPasswordChange>) {
  const idToken = yield select((state: AppState) => state.auth.idToken);

  const response = yield call(passwordChange, {
    idToken,
    passwordOld: action.payload.passwordOld,
    passwordNew: action.payload.passwordNew,
  });

  if (response.status !== 200) {
    yield put(userPasswordChangeError(response.data.detail));
    return;
  }

  yield put(userPasswordChangeSuccess());
}

function* handleUserDataLoad() {
  const idToken = yield select((state: AppState) => state.auth.idToken);

  try {
    const user = yield call(readUserData, idToken);
    yield put(userDataLoadSuccess(user));
  } catch (e) {
    yield put(userDataLoadError(e.message || e.errorMessage || 'Unknown error'));
    // Attention: If I am running into errors of not able to catch /me e.g. due to err_connection_refused
    // we force logout.
    window.open('/logout', '_self');
  }
}

function* watchPasswordChange() {
  yield takeEvery('USER_PASSWORD_CHANGE', handlePasswordChange);
}

function* watchUserDataLoad() {
  yield takeEvery('USER_DATA_LOAD', handleUserDataLoad);
}

export const userSagas = [watchUserDataLoad(), watchPasswordChange()];
